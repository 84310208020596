import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationTypeEnum} from "@constant/notification.constant";
import {NzIconModule} from "ng-zorro-antd/icon";

@Component({
    selector: 'app-notification-type',
    standalone: true,
    imports: [CommonModule, NzIconModule],
    templateUrl: './notification-type.component.html',
    styleUrls: ['./notification-type.component.less']
})
export class NotificationTypeComponent implements OnInit {

    @Input() type: string = '';

    readonly notificationTypeEnum = NotificationTypeEnum;

    constructor() {
    }

    ngOnInit(): void {
    }

    protected readonly NotificationTypeEnum = NotificationTypeEnum;
}
