import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouteConstant} from "@constant/route.constant";
import {genRouterLink} from "@util/route";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {RouterLinkActive, RouterLinkWithHref} from "@angular/router";

@Component({
    selector: 'app-client-profile-menu',
    standalone: true,
    imports: [CommonModule, NzAvatarModule, NzMenuModule, RouterLinkActive, RouterLinkWithHref],
    templateUrl: './client-profile-menu.component.html',
    styleUrls: ['./client-profile-menu.component.less']
})
export class ClientProfileMenuComponent implements OnInit {

    @Input() clientName: string;
    @Input() isPostLoginMenu = false;

    @Output() logoutEvent: EventEmitter<any> = new EventEmitter<any>();

    readonly routeConstant = RouteConstant;
    readonly genRouterLink = genRouterLink;

    constructor() {
    }

    ngOnInit(): void {
    }

    logout() {
        this.logoutEvent.emit();
    }
}
