import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalState} from '@core/store';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {skip, tap} from 'rxjs/operators';

@Component({
  selector: 'app-safe-area',
  templateUrl: './safe-area.component.html',
  styleUrls: ['./safe-area.component.scss']
})
export class SafeAreaComponent implements OnInit {
    showNotice = false;
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    constructor(private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.isMobile$.pipe(
            skip(1),
            tap(() => {
                this.showNotice = true;
                console.log('show notice:' + this.showNotice);
                this.cdRef.markForCheck();
            })
        ).subscribe();
    }

    decideViewport(change: boolean) {
        if (change) {
            window.location.reload();
            return;
        }
        this.showNotice = false;
        this.cdRef.markForCheck();
    }

}
