<div>
    <div class="d-flex mb-2">
        <span nz-icon nzType="info-circle" nzTheme="fill" class="pop-message-icon"></span>
        <div>
            <h4 class="mb-1">{{ titleText }}</h4>
            <p class="text-base">{{ bodyText }}</p>
        </div>
    </div>
    <div *ngIf="confirmBtn || cancelBtn" class="d-flex justify-content-end align-items-center">
        <button *ngIf="cancelBtn"
                (click)="emitData({cancel: true})"
                class="mr-2"
                nz-button
                nzType="default"
                nzSize="default"
        >{{ cancelBtnHtml }}</button>
        <button *ngIf="confirmBtn"
                (click)="emitData({confirm: true})"
                nz-button
                nzType="primary"
                nzSize="default"
        >{{ confirmBtnHtml }}</button>
    </div>
</div>
