import {Component, OnInit} from '@angular/core';
import {genRouterLink} from '@util/route';
import {RouteConstant} from '@constant/route.constant';
import {DatePipe} from '@angular/common';
import {Select} from '@ngxs/store';
import {GlobalState} from '@core/store';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-main-layout-footer',
  templateUrl: './footer.component.html',
    providers: [DatePipe]
})
export class FooterComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    logoUrl: String = 'assets/images/ifast-footer-logo.png';

    readonly genRouterLink = genRouterLink;
    readonly RouteConstant = RouteConstant;

    updateDate = '';

    constructor(private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.updateDate = this.datePipe.transform(new Date(), 'yyyy');
    }

}
