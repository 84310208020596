<div class="login">
    <div class="d-flex flex-column justify-content-center align-items-center gap-6"
         [ngClass]="{ 'px-4 flex-column' : (isMobile$ | async)}">
        <ng-container *ngTemplateOutlet="fMLogo"></ng-container>
        <ng-container *ngTemplateOutlet="loginCard"></ng-container>
    </div>
</div>

<ng-template #fMLogo>
    <img src="assets/images/ifm-white-logo-2x.svg"
         alt="iFast Fund Management"
         class="logo"
         [ngClass]="(isMobile$ | async)? 'mb-4' : 'mr-6'">
</ng-template>

<ng-template #loginCard>
    <div class="login-card bg-black-2" [ngClass]="{ 'web' : !(isMobile$ | async) }">
        <div class="text-center" [ngClass]="(isMobile$ | async)? 'mb-4' : 'mb-5 pb-1'">
            <h1 class="mb-2" [ngClass]="{'text-xl' : (isMobile$ | async)}">Login</h1>
            <p class="text-base mb-0">to iFAST Fund Management Platform Portal.</p>
        </div>
        <div class="mb-3" [formGroup]="form">
            <nz-input-group class="mb-3">
                <app-ui-input
                    required
                    nzType="text"
                    nzMaxLength="20"
                    formControlName="username"
                    nzPlaceHolder="Username"
                    (input)="onChangeUsername($event?.target?.value)"
                    (keyup.enter)="login()"
                ></app-ui-input>
            </nz-input-group>

            <nz-input-group>
                <app-ui-input
                    formControlName="password"
                    nzPlaceHolder="Password"
                    [nzType]="visiblePwd? 'text': 'password'"
                    [nzSuffix]="suffixTpl"
                    [autocomplete]="'new-password'"
                    (keyup.enter)="login()"
                    nzClass="bg-white">
                </app-ui-input>
            </nz-input-group>

            <ng-template #suffixTpl>
                    <span class="text-primary pointer" nz-icon [nzType]="visiblePwd? 'eye': 'eye-invisible'" nzTheme="outline"
                          (click)="visiblePwd = !visiblePwd"></span>
            </ng-template>
        </div>
        <div class="d-flex" [ngClass]="(isMobile$ | async)? 'flex-column mb-4' : 'justify-content-between align-items-center mb-6'">
            <div class="d-flex gap-1 align-items-center">
                <label nz-checkbox [(ngModel)]="rememberMeOption" class="our-funds-checkbox mr-1">
                    <span class="text-xs">Remember me</span>
                </label>
            </div>
            <div [ngClass]="{'mt-3' : (isMobile$ | async)}">
                <p class="text-xs mb-0">Forgot your <a class="text-primary" (click)="forgotUsername()">Username</a> or <a class="text-primary" (click)="forgotPassword()">Password</a> ?</p>
            </div>
        </div>
        <button class="w-100 mb-2"
                nz-button
                nzType="primary"
                [nzLoading]="loginLoading"
                (click)="login()"
        >Login
        </button>
        <p class="text-xs text-center">Need a demo? Email us at <a href="mailto:bd@ifastfm.com">bd@ifastfm.com</a></p>
    </div>
</ng-template>
