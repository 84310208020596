import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {AuthState, GlobalState} from "@core/store";
import {fromEvent, Observable, of, Subject, Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {catchError, debounceTime, filter, startWith, takeUntil, tap} from "rxjs/operators";
import {RouteConstant} from "@constant/route.constant";
import {mobilePostNavData, preNavData} from "@core/component/header/post-nav-data";
import {genRouterLink} from "@util/route";
import {SubHandlingService} from "@modules/shared-lib/common/service/subscription-handling.service";
import {AuthRestService} from "@api/service/api/auth.rest.service";
import {AuthService} from "@service/auth/auth.service";
import {Logout} from "@store/action/auth.action";
import {PopMessageService} from "@service/pop-message.service";

@Component({
    selector: 'app-pre-header',
    templateUrl: './pre-header.component.html',
    styleUrls: ['./pre-header.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SubHandlingService]
})
export class PreHeaderComponent implements OnInit, OnDestroy {

    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
    @Select(AuthState.getClientName) clientName$: Observable<string>;

    isAuthenticated = false;
    isClearBg = false;
    transparentBackgroundPages = [RouteConstant.ABOUT_US];
    isScrolled = false;
    private scrollSubscription: Subscription;

    // mobile view drawer
    drawerVisible = false;

    readonly mobilePostNavData = mobilePostNavData;
    readonly preNavData = preNavData;
    readonly genRouterLink = genRouterLink;

    // Subscription
    authenticationSubscription: Subscription;
    routerSubscription: Subscription;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private router: Router,
        private subHandlingService: SubHandlingService,
        private authService: AuthService,
        private authRestService: AuthRestService,
        private popMessageService: PopMessageService,
        private store: Store,
    ) {
    }

    ngOnDestroy(): void {
        this.authenticationSubscription?.unsubscribe();
        this.routerSubscription?.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.authenticationSubscription = this.isAuthenticated$.subscribe((res) => {
            this.isAuthenticated = res;
        });

        this.routerSubscription = this.router.events
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(event => event instanceof NavigationEnd),
                startWith(new NavigationEnd(0, this.router.url, this.router.url)) // Emits a fake NavigationEnd event to trigger the logic at initialization
            ).subscribe((event) => {
                // this.isClearBg = this.transparentBackgroundPages.some(route => this.router.url === `/${route}`);
            });

        if (this.isAuthenticated) {
            this.checkActive();
        }
    }

    ngAfterViewInit(): void {
        this.scrollSubscription = fromEvent(window, 'scroll')
            .pipe(debounceTime(100))
            .subscribe(() => {
                const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
                const newScrolled = scrollPosition > 5;
                if (this.isScrolled && this.drawerVisible && !newScrolled) {
                    // remain isScrolled for header after drawer opens
                    // new scroll will change it to false
                    this.isScrolled = true;
                }
                else {
                    this.isScrolled = newScrolled;
                }
            });
    }

    navigateToLogin() {
        this.router.navigate([RouteConstant.LOGIN]).then();
    }

    checkActive() {
        this.subHandlingService.once(
            this.authRestService.checkToken().pipe(
                tap((res: any) => {
                    if (res.data.active) {
                        return true;
                    }
                    this.authService.logout();
                    return false;
                }),
                catchError((error) => {
                    console.log('error', error);
                    this.authService.dispatchLogout();
                    return of(false);
                })
            )
        );
    }

    showDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    logout() {
        this.router.navigate([RouteConstant.LOGIN]).then(
            () => {
                this.authRestService.logout().subscribe();
                this.popMessageService.openTopPopMessageModal({
                    type: 'success',
                    titleText: 'Success!',
                    bodyText: 'Logout Successful.'
                }).then();
                this.store.dispatch(new Logout());
            }
        );
    }
}
