import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {genRouterLink} from "@util/route";
import {mobilePostNavData, postNavData} from "@core/component/header/post-nav-data";
import {PageConstant} from "@constant/page.constant";
import {RouteConstant} from "@constant/route.constant";
import {Logout} from "@store/action/auth.action";
import {AuthRestService} from '@shared/service/api/auth.rest.service';
import {PopMessageService} from "@service/pop-message.service";
import {Select, Store} from "@ngxs/store";
import {RightsService} from "@service/auth/rights.service";
import {AuthState} from "@core/store";
import {interval, Observable, Subject, Subscription} from "rxjs";
import {startWith, switchMap, takeUntil} from "rxjs/operators";
import {AppConstant} from "@constant/app.constant";
import {RecommendationInfoModel} from "@model/portfolio-management/i-portfolio-recommendation.model";
import {INav} from "@core/interface/i-nav";
import {HeaderService} from "@core/component/header/header.service";
import {AccountRestService} from "@api/service/api/account.rest.service";
import {environment} from "@env";

@Component({
    selector: 'app-post-header',
    templateUrl: './post-header.component.html',
    styleUrls: ['./post-header.component.less']
})
export class PostHeaderComponent implements OnInit, OnDestroy {

    @Select(AuthState.getClientName) clientName$: Observable<string>;

    showChildrenRoute = false;

    // mobile
    drawerVisible = false;

    // order
    pendingOrderApiSubscription: Subscription;
    pendingOrders: number = 0;
    processingOrders: number = 0;

    postNavData = [...postNavData];
    mobilePostNavData = [...mobilePostNavData];

    private unsubscribe$ = new Subject<void>();

    constructor(
        private router: Router,
        private authRestService: AuthRestService,
        private popMessageService: PopMessageService,
        private accountRestService: AccountRestService,
        private store: Store,
        private rightsService: RightsService,
        private headerService: HeaderService,
    ) {}

    ngOnDestroy(): void {
        this.pendingOrderApiSubscription?.unsubscribe()
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        if (!environment.dev) {
            this.pendingOrderApiSubscription = interval(3000).pipe(
                startWith(0),
                switchMap(() => this.accountRestService.getPendingOrders()),
                takeUntil(this.unsubscribe$)
            ).subscribe(
                res => {
                    if (res && res.status === AppConstant.RESPONSE_SUCCESS) {
                        this.processNavData(res.data);
                    } else {
                        this.processNavData({});
                    }
                },
                error => {
                    this.processNavData({});
                }
            );
        }
    }

    redirectToDestination(url: string) {
        this.router.navigate([genRouterLink(url)]).then(() => {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                });
            }, 100);
        });
    }

    isCurrentRoute(url?: string) {
        return this.router.url?.includes(url);
    }

    hasAccess(access: string[]) {
        return this.rightsService.checkRights(access);
    }

    checkForLogout(title: string) {
        if (title === PageConstant.LOGOUT) {
            this.logout();
        }
    }

    showDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    logout() {
        this.router.navigate([RouteConstant.LOGIN]).then(
            () => {
                this.authRestService.logout().subscribe();
                this.popMessageService.openTopPopMessageModalPreLoginModal({
                    type: 'success',
                    titleText: 'Success!',
                    bodyText: 'Logout Successful.'
                }).then();
                this.store.dispatch(new Logout());
            }
        );
    }

    processNavData(infoModel: RecommendationInfoModel) {
        this.headerService.updateNotification(infoModel.hasUnreadNotification || false);
        const pendingOrder = infoModel.totalPendingOrders || 0;
        const processingOrder = infoModel.totalProcessingOrders || 0;
        if (this.pendingOrders === pendingOrder && this.processingOrders === processingOrder) {
            return;
        }
        if (this.pendingOrders !== pendingOrder) {
            this.pendingOrders = pendingOrder;
            this.headerService.updatePendingOrders(pendingOrder);
        }
        if (this.processingOrders !== processingOrder) {
            this.processingOrders = processingOrder;
            this.headerService.updateProcessingOrders(processingOrder);
        }
        // find pending order recursively
        const pendingOrdersNavItem = this.findNavItemByTitle(postNavData, PageConstant.MY_APPROVALS);
        const processingOrdersNavItem = this.findNavItemByTitle(postNavData, PageConstant.PROCESSING);
        const mobilePendingOrderNav = this.findNavItemByTitle(mobilePostNavData, PageConstant.MY_APPROVALS);
        const mobileProcessingOrderNav = this.findNavItemByTitle(mobilePostNavData, PageConstant.PROCESSING);
        this.setPendingOrders(pendingOrdersNavItem, pendingOrder);
        this.setPendingOrders(mobilePendingOrderNav, pendingOrder);

        this.setPendingOrders(processingOrdersNavItem, processingOrder);
        this.setPendingOrders(mobileProcessingOrderNav, processingOrder);
    }

    setPendingOrders(navItems: INav, pendingOrder: number) {
        if (navItems) {
            navItems.pendingNumber = pendingOrder;
        }
    }

    findNavItemByTitle(navData: INav[], title: string): INav | null {
        for (const item of navData) {
            if (item.title === title) {
                return item;
            }
            if (item.children) {
                const foundItem = this.findNavItemByTitle(item.children, title);
                if (foundItem) {
                    return foundItem;
                }
            }
        }
        return null;
    }
}
