<ng-container [ngTemplateOutlet]="AUD" *ngIf="currency === 'AUD'"></ng-container>
<ng-container [ngTemplateOutlet]="BRL" *ngIf="currency === 'BRL'"></ng-container>
<ng-container [ngTemplateOutlet]="CAD" *ngIf="currency === 'CAD'"></ng-container>
<ng-container [ngTemplateOutlet]="CNH" *ngIf="currency === 'CNH' || currency === 'CNY'"></ng-container>
<ng-container [ngTemplateOutlet]="CZK" *ngIf="currency === 'CZK'"></ng-container>
<ng-container [ngTemplateOutlet]="EUR" *ngIf="currency === 'EUR'"></ng-container>
<ng-container [ngTemplateOutlet]="GBP" *ngIf="currency === 'GBP'"></ng-container>
<ng-container [ngTemplateOutlet]="HKD" *ngIf="currency === 'HKD'"></ng-container>
<ng-container [ngTemplateOutlet]="IDR" *ngIf="currency === 'IDR'"></ng-container>
<ng-container [ngTemplateOutlet]="IND" *ngIf="currency === 'IND'"></ng-container>
<ng-container [ngTemplateOutlet]="JPY" *ngIf="currency === 'JPY'"></ng-container>
<ng-container [ngTemplateOutlet]="KRW" *ngIf="currency === 'KRW'"></ng-container>
<ng-container [ngTemplateOutlet]="MYR" *ngIf="currency === 'MYR'"></ng-container>
<ng-container [ngTemplateOutlet]="NZD" *ngIf="currency === 'NZD'"></ng-container>
<ng-container [ngTemplateOutlet]="SEK" *ngIf="currency === 'SEK'"></ng-container>
<ng-container [ngTemplateOutlet]="SGD" *ngIf="currency === 'SGD'"></ng-container>
<ng-container [ngTemplateOutlet]="SWP" *ngIf="currency === 'SWP'"></ng-container>
<ng-container [ngTemplateOutlet]="THB" *ngIf="currency === 'THB'"></ng-container>
<ng-container [ngTemplateOutlet]="TWD" *ngIf="currency === 'TWD'"></ng-container>
<ng-container [ngTemplateOutlet]="USD" *ngIf="currency === 'USD'"></ng-container>


<ng-template #AUD>
    <img src="assets/images/currency-flag/aud-01.svg" />
</ng-template>

<ng-template #BRL>
    <img src="assets/images/currency-flag/brl-01.svg" />
</ng-template>

<ng-template #CAD>
    <img src="assets/images/currency-flag/cad-01.svg" />
</ng-template>

<ng-template #CNH>
    <img src="assets/images/currency-flag/chn-01.svg" />
</ng-template>

<ng-template #CZK>
    <img src="assets/images/currency-flag/czk-01.svg" />
</ng-template>

<ng-template #EUR>
    <img src="assets/images/currency-flag/eur-01.svg" />
</ng-template>

<ng-template #GBP>
    <img src="assets/images/currency-flag/gbp-01.svg" />
</ng-template>

<ng-template #HKD>
    <img src="assets/images/currency-flag/hkd-01.svg" />
</ng-template>

<ng-template #IDR>
    <img src="assets/images/currency-flag/idr-01.svg" />
</ng-template>

<ng-template #IND>
    <img src="assets/images/currency-flag/ind-01.svg" />
</ng-template>

<ng-template #JPY>
    <img src="assets/images/currency-flag/jpy-01.svg" class="jpyIcon" />
</ng-template>

<ng-template #KRW>
    <img src="assets/images/currency-flag/krw-01.svg" />
</ng-template>

<ng-template #MYR>
    <img src="assets/images/currency-flag/myr-01.svg" />
</ng-template>

<ng-template #NZD>
    <img src="assets/images/currency-flag/nzd-01.svg" />
</ng-template>

<ng-template #SEK>
    <img src="assets/images/currency-flag/sek-01.svg" />
</ng-template>

<ng-template #SGD>
    <img src="assets/images/currency-flag/sgd-01.svg" />
</ng-template>

<ng-template #SWP>
    <img src="assets/images/currency-flag/swp-01.svg" />
</ng-template>

<ng-template #THB>
    <img src="assets/images/currency-flag/thb-01.svg" />
</ng-template>

<ng-template #TWD>
    <img src="assets/images/currency-flag/twd-01.svg" />
</ng-template>

<ng-template #USD>
    <img src="assets/images/currency-flag/usd-01.svg" />
</ng-template>
