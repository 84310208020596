import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Select} from "@ngxs/store";
import {GlobalState} from "@core/store";
import {Observable} from "rxjs";
import {CommonModule} from "@angular/common";
import {BreadcrumbModule} from "xng-breadcrumb";
import {RouterLinkWithHref} from "@angular/router";

@Component({
    selector: 'app-post-breadcrumb',
    standalone: true,
    imports: [CommonModule, BreadcrumbModule, RouterLinkWithHref],
    templateUrl: './post-breadcrumb.component.html',
    styleUrls: ['./post-breadcrumb.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class PostBreadcrumbComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    constructor() {
    }

    ngOnInit(): void {
    }

}
