import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AccountRestService} from '@api/service/api/account.rest.service';
import {SubHandlingService} from '@modules/shared-lib/common/service/subscription-handling.service';
import {tap} from 'rxjs/operators';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {AppConstant} from '@constant/app.constant';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {AuthService} from '@service/auth/auth.service';
import {PresessionModel, RequestOtpModel} from '@model/account/fm-client.model';
import {VerifyUserRequestModel} from '@model/account/verify-user-request.model';
import {Observable} from 'rxjs';
import {PopMessageService} from '@service/pop-message.service';
import {ResetPasswordModalComponent} from '@modules/auth/component/reset-password-modal/reset-password-modal.component';
import {Select, Store} from '@ngxs/store';
import {GlobalState} from '@core/store';
import {BaseOtpComponent} from "@modules/shared-lib/common/base-otp/base-otp.component";
import {AuthConstant} from "@constant/auth.constant";

@Component({
    selector: 'app-two-fa-registration',
    templateUrl: './two-fa-registration.component.html',
    styleUrls: ['./two-fa-registration.component.less'],
    providers: [SubHandlingService]
})
export class TwoFaRegistrationComponent extends BaseOtpComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    @Input() username: string = '';
    @Input() clientEmail: string = '';
    sendEmailOtpLoading = false;
    verifyEmailOtpLoading = false;
    isMobile: boolean = false;

    constructor(
        private modal: NzModalRef,
        private fb: FormBuilder,
        private authService: AuthService,
        private accountRestService: AccountRestService,
        private subHandlingService: SubHandlingService,
        private popMessageService: PopMessageService,
        private nzModalService: NzModalService,
        private store: Store,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initOtp();

        this.isMobile$.subscribe((isMobile: boolean) => {
            this.isMobile = isMobile;
        });

        this.sendEmailOtp();
    }

    closeModal(message?: string) {
        this.authService.twoFaBlock = false;
        this.modal.close(message);
    }


    sendEmailOtp() {
        if (this.clientEmail) {
            this.clearOtpInput();
            this.sendEmailOtpLoading = true;
            this.changeCountdownFlag();
            this.authService.getPresession(AuthConstant.PRESESSION_TYPE_OTP).subscribe(res => {
                const requestOtpModel: RequestOtpModel = {
                    sid: res.sid,
                    randomNumber: res.randomNumber,
                    type: AuthConstant.PRESESSION_TYPE_OTP,
                    email: this.clientEmail,
                    username: this.username,
                }

                this.subHandlingService.once(
                    this.accountRestService.sendTwoFaEmailOtp(requestOtpModel).pipe(tap((res) => {
                        if (res && res.data && res.status === AppConstant.RESPONSE_SUCCESS) {
                            this.otpPrefix = res.data;
                        } else {
                            this.popMessageService.openNewPopUpErrorModal('One Time Password Request Error').then();
                        }
                        this.sendEmailOtpLoading = false;
                    }, () => {
                        this.popMessageService.openNewPopUpErrorModal('One Time Password Request Error').then();
                        this.sendEmailOtpLoading = false;
                    }))
                );
            })
        }
    }

    verifyEmailOtp() {
        if (this.clientEmail && this.otpValue.length === 6) {
            this.verifyEmailOtpLoading = true;
            this.subHandlingService.once(
                this.authService.getPresession().pipe(tap((res: PresessionModel) => {
                    if (res) {
                        const rsa = this.authService.getNewRsaEngineWithKeys(res.publicKey, res.sid, res.randomNumber);
                        let encryptedOtp = rsa.encryptPIN1(this.otpValue);

                        const verifyUserRequestModel: VerifyUserRequestModel = {
                            otpPrefix: this.otpPrefix,
                            encryptedOtp: encryptedOtp,
                            e2eeRandom: res.randomNumber,
                            email: this.clientEmail,
                            username: this.username
                        };

                        this.subHandlingService.once(
                            this.accountRestService.authenticateTwoFaOtp(verifyUserRequestModel).pipe(tap((subRes: IResponse<boolean>) => {
                                if (subRes && subRes.data && subRes.status === AppConstant.RESPONSE_SUCCESS) {
                                    this.openSuccessVerificationModal();
                                    this.closeModal('success');
                                } else {
                                    // wrong otp number
                                    this.popMessageService.openNewPopUpErrorModal(
                                        'Your One-Time Password (OTP) is invalid or expired. Please enter a valid OTP or click "resend" OTP to get a new OTP.'
                                    ).then();
                                }
                                this.verifyEmailOtpLoading = false;
                            }, () => {
                                this.popMessageService.openNewPopUpErrorModal(
                                    'Your One-Time Password (OTP) is invalid or expired. Please enter a valid OTP or click "resend" OTP to get a new OTP.'
                                ).then();
                                this.verifyEmailOtpLoading = false;
                            }))
                        );
                    }
                }, () => {
                    this.verifyEmailOtpLoading = false;
                }))
            );
        }
    }

    openSuccessVerificationModal() {
        this.popMessageService.openNewPopUpModal({
            titleText: 'Verification Success',
            bodyText: 'Thank you for your support, we have successfully verified your email address.',
            confirmBtn: true,
            confirmBtnHtml: 'Reset Password',
            type: 'success',
        }).then(() => {
            this.nzModalService.create({
                nzWrapClassName: this.isMobile ? 'basic-ui-modal grey-modal' : 'basic-ui-modal grey-modal w650-screen',
                nzCentered: true,
                nzClosable: true,
                nzMaskClosable: false,
                nzContent: ResetPasswordModalComponent,
                nzComponentParams: {
                    title: 'Reset Password',
                    type: 'resetPass',
                    username: this.username,
                }
            });
        });
    }

}
