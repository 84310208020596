import {Injectable} from '@angular/core';
import {ClientUrlBasic} from '@shared/url-constant/ClientUrlBasic';
import {environment} from '@env';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {AuthStateModel} from '@store/state/auth.state';
import {CustomURLEncoder} from '@core/encoder/custom-url-encoder';
import {PresessionModel} from '@model/account/fm-client.model';
import {HttpService} from '@shared/service/template/http.service';
import {HttpParamsService} from '@api/service/template/http-params.service';

@Injectable({providedIn: 'root'})
export class AuthRestService {
    private API_SERVER = environment.apiUrl;

    readonly BASE_URL = ClientUrlBasic.AUTH;

    constructor(
        private httpService: HttpService,
        private paramsService: HttpParamsService,
    ) {}

    checkToken(): Observable<IResponse<boolean>> {
        return this.httpService.get(this.BASE_URL + ClientUrlBasic.CHECK);
    }

    refreshToken(refreshToken: string): Observable<IResponse<AuthStateModel>> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const params = new HttpParams({ encoder: new CustomURLEncoder() })
            .set('refreshToken', refreshToken);

        return this.httpService.postWithCustomHeader(this.BASE_URL + ClientUrlBasic.REFRESH_TOKEN,
            headers, null, params
        );
    }

    getPresession(type: string): Observable<IResponse<PresessionModel>> {
        const params = this.paramsService.buildHttpParams({type});
        return this.httpService.postIResponseWithParam(this.BASE_URL + ClientUrlBasic.GET_PRESESSION, params);
    }

    login(username: string, password: string, randomNumber: string, sid: string): Observable<IResponse<AuthStateModel>> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const params = new HttpParams({ encoder: new CustomURLEncoder() })
            .set('username', username)
            .set('password', password)
            .set('randomNumber', randomNumber)
            .set('sid', sid);

        return this.httpService.postWithCustomHeader(ClientUrlBasic.LOGIN,
            headers, null, params
        );
    }

    logout() {
        return this.httpService.post(ClientUrlBasic.LOGOUT);
    }
}
