import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzIconModule} from "ng-zorro-antd/icon";

@Component({
    selector: 'app-top-pop-message-modal-pre',
    standalone: true,
    imports: [CommonModule, NzIconModule],
    templateUrl: './top-pop-message-modal-pre.component.html',
    styleUrls: ['./top-pop-message-modal-pre.component.scss']
})
export class TopPopMessageModalPreComponent implements OnInit {

    @Input() type: string;
    @Input() titleText: string;
    @Input() bodyText: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
