import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegexConstant} from '@constant/regex.constant';
import {AccountRestService} from '@api/service/api/account.rest.service';
import {SubHandlingService} from '@modules/shared-lib/common/service/subscription-handling.service';
import {VerifyUserRequestModel} from '@model/account/verify-user-request.model';
import {tap} from 'rxjs/operators';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {AppConstant} from '@constant/app.constant';
import {PopMessageService} from '@service/pop-message.service';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {RouteConstant} from '@constant/route.constant';

@Component({
    selector: 'app-forgot-username-modal',
    templateUrl: './forgot-username-modal.component.html',
    styleUrls: ['./forgot-username-modal.component.less'],
    providers: [SubHandlingService]
})
export class ForgotUsernameModalComponent implements OnInit {

    form: FormGroup;
    submitLoading = false;

    constructor(
        private modal: NzModalRef,
        private fb: FormBuilder,
        private accountRestService: AccountRestService,
        private subHandlingService: SubHandlingService,
        private popMessageService: PopMessageService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    closeModal() {
        this.modal.close();
    }

    initForm() {
        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.pattern(RegexConstant.REGEX_EMAIL)]],
            nric: [null, [Validators.required]]
        });
    }

    get emailControl() {
        return this.form.get('email');
    }

    get nricControl() {
        return this.form.get('nric');
    }

    sendForgetUsernameEmail() {
        if (this.form.valid) {
            this.submitLoading = true;
            const verifyUserRequestModel: VerifyUserRequestModel = {
                email: this.emailControl.value,
                nric: this.nricControl.value
            };
            this.subHandlingService.once(
                this.accountRestService.sendForgetUsernameEmail(verifyUserRequestModel).pipe(tap((res: IResponse<boolean>) => {
                    if (res && res.data && res.status === AppConstant.RESPONSE_SUCCESS) {
                        this.closeModal();
                        this.openSuccessSendEmailModal();
                    } else {
                        this.popMessageService.openNewPopUpErrorModal('Account is not found').then();
                    }
                    this.submitLoading = false;
                }, error => {
                    this.popMessageService.openNewPopUpErrorModal('Something wrong happens').then();
                    this.submitLoading = false;
                }))
            );
        }
    }

    openSuccessSendEmailModal() {
        this.popMessageService.openNewPopUpModal({
            customTypeImg: 'assets/images/account/email-sent.svg',
            titleText: 'Email Sent',
            bodyText: 'Your Login ID will be sent to your registered email',
            confirmBtn: true,
            confirmBtnHtml: 'Go To Login',
        }).then((res) => {
            if (res?.confirm) {
                this.router.navigate([RouteConstant.LOGIN]).then();
            }
        });
    }

}
