import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GlobalState } from '@core/store';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-web-scaffold',
    template: `
        <ng-container *ngIf="show$ | async">
            <ng-content></ng-content>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None
})
export class WebScaffoldComponent implements OnInit {
    @Input() webComponentUnmountFn: any;

    show$: Observable<boolean>;

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.show$ = this.store.select(GlobalState.isMobile).pipe(map((mobile: boolean) => !mobile), tap((show: boolean) => {
            if (!show && this.webComponentUnmountFn && (typeof this.webComponentUnmountFn) === 'function') {
                try {
                    this.webComponentUnmountFn();
                } catch (_) {

                }
            }
        }));
    }
}
