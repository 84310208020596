import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, throwError} from 'rxjs';
import {AuthState} from '@store/state/auth.state';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {catchError, filter, finalize, switchMap, take, takeUntil} from 'rxjs/operators';
import {AppConstant} from '@constant/app.constant';
import {LoginSuccess} from '@store/action/auth.action';
import {Store} from '@ngxs/store';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Router} from '@angular/router';
import {ClientUrlBasic} from '@api/url-constant/ClientUrlBasic';
import {AuthRestService} from '@api/service/api/auth.rest.service';
import {AuthService} from '@service/auth/auth.service';

@Injectable()
export class ProtectedApiInterceptor implements HttpInterceptor {

    constructor(
        private authRestService: AuthRestService,
        private store: Store,
        private nzMessageService: NzMessageService,
        private router: Router,
        private authService: AuthService,
    ) {
    }
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private refreshFailedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private static _isUnauthorized(error: HttpErrorResponse): boolean {
        return error.status === 401;
    }

    private static _error(error: HttpErrorResponse): boolean {
        return error.status !== 200 && error.status !== 401 && error.status !== 1300;
    }

    private static _customError(error: HttpErrorResponse): boolean {
        return error.error?.errorCode !== 1300;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthHeader(request)).pipe(
            switchMap((event: HttpEvent<any>) => {
                // return event;
                return this.handleCheckApiInactive(event, request, next);
            }),
            catchError((error, caught) => {
                if (error instanceof HttpErrorResponse) {
                    if (ProtectedApiInterceptor._error(error) && ProtectedApiInterceptor._customError(error)) {
                        this.httpErrorResponseHandler(error, request.url);

                    } else if (ProtectedApiInterceptor._isUnauthorized(error) && !request.url.includes(ClientUrlBasic.LOGIN)) {
                        return this.handle401Error(request, next);
                    }
                    return throwError(error.error);
                }
                return caught;
            }),
            takeUntil(this.refreshFailedSubject.pipe(
                filter(failed => failed),
                take(1)
            ))
        );
    }

    private handleCheckApiInactive(event: HttpEvent<any>, request: HttpRequest<any>, next: HttpHandler) {
        if (event instanceof HttpResponse) {
            if (request.url === ClientUrlBasic.AUTH + ClientUrlBasic.CHECK) {
                if (!event.body.data.active) {
                    return this.handle401Error(request, next);
                }
            }
        }
        return of(event);
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const refreshToken = this.store.selectSnapshot(AuthState.getRefreshToken);
            if (refreshToken) {
                const fmClientModel = this.store.selectSnapshot(AuthState.getFmClient);
                return this.authRestService.refreshToken(refreshToken).pipe(
                    switchMap((res: any) => {
                        console.log('refresh token api', res);
                        if (res.status === AppConstant.RESPONSE_FAILED) {
                            return throwError('Refresh token failed');
                        }
                        res.data.fmClientModel = fmClientModel;
                        this.store.dispatch(new LoginSuccess(res.data));
                        this.refreshTokenSubject.next(this.store.selectSnapshot(AuthState.getAccessToken));
                        return next.handle(this.addAuthHeader(request));
                    }),
                    catchError((err) => {
                        console.log('err', err);
                        this.nzMessageService.error('You have been logged out.');
                        this.handleLogout();
                        return EMPTY;
                    }),
                    finalize(() => {
                        this.isRefreshing = false;
                    })
                );
            } else {
                this.authService.logout();
            }
        }
        return this.refreshTokenSubject.pipe(
            filter(token => (token != null)),
            take(1),
            switchMap(() => {
                return next.handle(this.addAuthHeader(request));
            })
        );
    }

    httpErrorResponseHandler(error: HttpErrorResponse, _url: string): void {
        this.nzMessageService.error(error.error.errorMessage);
    }

    handleLogout() {
        this.authRestService.logout().pipe(
            finalize(() => {
                this.refreshFailedSubject.next(true);
                // Reset the subject after a short delay
                setTimeout(() => {
                    this.refreshFailedSubject.next(false);
                }, 100);
            })
        ).subscribe();
        this.authService.dispatchLogout();
    }

    private addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {

        const accessToken: string = this.store?.selectSnapshot(AuthState.getAccessToken);

        req = req.clone({
            setHeaders: {
                'Accept-Language': 'en',
                'Access-Control-Allow': 'true',
                'Authorization': 'Bearer ' + accessToken,
            },
            url: req.url
        });
        return req;
    }
}
