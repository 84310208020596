import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env';
import {IResponse} from '@modules/shared-lib/common/model/i-response';

@Injectable({providedIn: 'root'})
export class HttpService {
    private API_SERVER = '';
    constructor(private httpClient: HttpClient) {
        // for local let proxy catch /REST to route
        if (!environment.dev) {
            this.API_SERVER = environment.apiUrl;
        }
    }

    private static getSkipCheckHeader() {
        const header = new HttpHeaders({'skip': 'true'});
        return {headers: header};
    }

    private static getNonRedirectHeader() {
        const header = new HttpHeaders({'nonRedirect': 'true'});
        return {headers: header};
    }

    public dataToUrl(url: string, data?: any) {
        if (data != null) {
            let isFirst = true;
            for (const objKey of Object.keys(data)) {
                if (isFirst) {
                    url += '?' + objKey + '=' + data[objKey];
                    isFirst = false;
                } else {
                    url += '&' + objKey + '=' + data[objKey];
                }
            }
        }
        return url;
    }

    // use these 3
    public postIResponse(url: string): Observable<IResponse<any>> {
        return this.httpClient.post<IResponse<any>>(this.API_SERVER + url, {});
    }

    public postIResponseWithBody(url: string, body?: any): Observable<IResponse<any>> {
        return this.httpClient.post<IResponse<any>>(this.API_SERVER + url, body);
    }

    public postIResponseWithParam(url: string, params?: HttpParams): Observable<IResponse<any>> {
        return this.httpClient.post<IResponse<any>>(this.API_SERVER + url, {}, {params});
    }

    public postIResponseWithBodyAndParam(url: string, body?: any, params?: HttpParams): Observable<IResponse<any>> {
        return this.httpClient.post<IResponse<any>>(this.API_SERVER + url, body, {params});
    }
    //

    public post(url: string, body?: any): Observable<any> {
        return this.httpClient.post<any>(this.API_SERVER + url, body);
    }

    public postWithParam(url: string, data?: any): Observable<any> {
        url = this.dataToUrl(url, data);
        return this.httpClient.post<any>(this.API_SERVER + url, data);
    }

    public postSkipCheck(url: string, body?: any): Observable<any> {
        return this.httpClient.post<any>(this.API_SERVER + url, body, HttpService.getSkipCheckHeader());
    }

    public postNonRedirect(url: string, body?: any): Observable<any> {
        return this.httpClient.post<any>(this.API_SERVER + url, body, HttpService.getNonRedirectHeader());
    }

    public postSkipCheckWithParam(url: string, data?: any): Observable<any> {
        url = this.dataToUrl(url, data);
        return this.httpClient.post<any>(this.API_SERVER + url, null, HttpService.getSkipCheckHeader());
    }

    public postNonRedirectWithParam(url: string, data?: any): Observable<any> {
        url = this.dataToUrl(url, data);
        return this.httpClient.post<any>(this.API_SERVER + url, null, HttpService.getNonRedirectHeader());
    }

    public postWithCustomHeader(url: string, header: HttpHeaders, params?: HttpParams, body?: any): Observable<IResponse<any>> {
        return this.httpClient.post<any>(this.API_SERVER + url, body, {params: params, headers: header});
    }

    public get(url: string, params?: HttpParams): Observable<any> {
        return this.httpClient.get<any>(this.API_SERVER + url, {params: params});
    }

    public getSkipCheck(url: string, data?: any): Observable<any> {
        url = this.dataToUrl(url, data);
        return this.httpClient.get<any>(this.API_SERVER + url, HttpService.getSkipCheckHeader());
    }

    public getNonRedirect(url: string, data?: any): Observable<any> {
        url = this.dataToUrl(url, data);
        return this.httpClient.get<any>(this.API_SERVER + url, HttpService.getNonRedirectHeader());
    }

    public downloadFileWithoutSaveAs(url: string, fileName: string, fileType: string, dataByBody: boolean, data?: any, params?: HttpParams) {
        let bodyData = {};
        if (dataByBody) {
            bodyData = data;
        } else {
            url = this.dataToUrl(url, data);
        }
        const httpHeaders = new HttpHeaders({
            'Accept': 'application/octet-stream'
        });
        return this.httpClient.post(this.API_SERVER + url, bodyData, {
            headers: httpHeaders,
            responseType: 'arraybuffer',
            params: params
        });
    }

    public downloadFileFullResponse(url: string, body?: any, params?: HttpParams): Observable<HttpResponse<ArrayBuffer>> {
        const httpHeaders = new HttpHeaders({
            'Accept': 'application/octet-stream'
        });
        return this.httpClient.post(this.API_SERVER + url, body, {
            headers: httpHeaders,
            observe: 'response',
            responseType: 'arraybuffer',
            params: params
        });
    }
}
