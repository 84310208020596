<ng-container *nzModalTitle>
    <h4 class="mb-0">{{ title }}</h4>
</ng-container>

<div class="bg-black-3 p-3 rounded-lg shadow-sm mb-4">
    <h4 class="text-base mb-2">Note(s)</h4>
    <p class="text-xs text-black mb-0">Your password must be 8-20 characters long, include letters, numbers, special characters, and at least one case-sensitive letter.</p>
</div>

<div class="text-center">
    <div class="mb-3">
        <img src="assets/images/account/pc-question.svg" alt="iFAST FM">
    </div>

    <h6 class="mb-3 text-center">Reset Password</h6>
    <div class="text-left">
        <ng-container *ngTemplateOutlet="passwordFormTpl"></ng-container>
    </div>

</div>

<ng-container *nzModalFooter>
    <div class="text-center">
        <button [disabled]="passwordForm.invalid"
                nz-button
                [nzLoading]="submitLoading"
                nzType="primary"
                (click)="submit()"
        >Submit
        </button>
    </div>
</ng-container>

<ng-template #passwordFormTpl>
    <form nz-form [formGroup]="passwordForm" class="form-container">
        <div class="mb-2">
            <nz-form-item class="mb-0">
                <nz-form-control>
                    <nz-input-group>
                        <app-ui-input formControlName="password" nzPlaceHolder="Password"
                                      [nzType]="visiblePwd? 'text': 'password'"
                                      [nzSuffix]="suffixPasswordTpl"
                                      nzMaxLength="20"
                                      [autocomplete]="'new-password'"
                                      (input)="validateConfirmPassValue()">
                        </app-ui-input>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <div *ngIf="passwordControl.errors?.pattern" class="d-flex align-items-center text-warning text-xs">
                <span class="mr-1 text-warning" size="small" nz-icon nzType="info-circle" nzTheme="fill"></span>
                Please enter a valid password.
            </div>

            <ng-template #suffixPasswordTpl>
                    <span class="text-primary" nz-icon [nzType]="visiblePwd? 'eye': 'eye-invisible'" nzTheme="outline"
                          (click)="visiblePwd = !visiblePwd"></span>
            </ng-template>
        </div>
        <div>
            <nz-form-item class="mb-0">
                <nz-form-control>
                    <nz-input-group>
                        <app-ui-input formControlName="confirmPass" nzPlaceHolder="Confirm Password"
                                      [nzType]="visibleConfirmPwd? 'text': 'password'"
                                      [nzSuffix]="suffixConfirmPassTpl"
                                      [autocomplete]="'new-password'"
                                      nzMaxLength="20"
                                      (input)="validateConfirmPassValue()">
                        </app-ui-input>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <div *ngIf="confirmPassControl.errors?.custom" class="d-flex align-items-center text-warning text-xs">
                <span class="text-warning mr-1" size="small" nz-icon nzType="info-circle" nzTheme="fill"></span>
                The re-enter password does not match.
            </div>

            <ng-template #suffixConfirmPassTpl>
                    <span class="text-primary" nz-icon [nzType]="visibleConfirmPwd? 'eye': 'eye-invisible'" nzTheme="outline"
                          (click)="visibleConfirmPwd = !visibleConfirmPwd"></span>
            </ng-template>
        </div>
    </form>
</ng-template>
