import {Injectable} from "@angular/core";
import {HttpService} from "@api/service/template/http.service";
import {HttpParamsService} from "@api/service/template/http-params.service";
import {NotificationSearchModel} from "@model/notification/notification-search.model";
import {ClientUrlBasic} from "@api/url-constant/ClientUrlBasic";

@Injectable({providedIn: 'root'})
export class NotificationRestService {

    constructor(
        private httpService: HttpService,
        private paramsService: HttpParamsService,
    ) {}

    findNotifications(notificationSearchModel: NotificationSearchModel) {
        return this.httpService.postIResponseWithBody(ClientUrlBasic.FIND_NOTIFICATIONS, notificationSearchModel);
    }

    findDateNotification(notificationSearchModel: NotificationSearchModel) {
        return this.httpService.postIResponseWithBody(ClientUrlBasic.FIND_DATE_NOTIFICATIONS, notificationSearchModel);
    }

    readNotification(id: number) {
        const params = this.paramsService.buildHttpParams({id})
        return this.httpService.postIResponseWithParam(ClientUrlBasic.READ_NOTIFICATION, params);
    }

    readAllNotification() {
        return this.httpService.postIResponse(ClientUrlBasic.READ_ALL_NOTIFICATION);
    }
}
