<div class="safe-Area" *ngIf="showNotice">
    
        <div class="container h-100 d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column text-center">
            <ng-container *ngIf="!(isMobile$|async)">
                <h4 class="text-white">To get better viewing experience, please refresh browser to access {{(isMobile$|async) ? 'Mobile' : 'Web'}} view</h4>
                <h4 class="text-white">Changes you made may not be saved.</h4>
            </ng-container>
            <ng-container *ngIf="(isMobile$|async)">
                <p class="text-white">To get better viewing experience, please refresh browser to access {{(isMobile$|async) ? 'Mobile' : 'Web'}} view</p>
                <p class="text-white">Changes you made may not be saved.</p>
            </ng-container>
<!--            <button nz-button class="outline text-center my-3" (click)="decideViewport(false)">-->
<!--                <span class="text-white">Continue in {{(isMobile$|async) ? 'Web' : 'Mobile'}}</span>-->
<!--            </button>-->
<!--            <br/>-->
            <button nz-button class="text-center align-self-center my-3" nzType="primary" (click)="decideViewport(true)" style="max-width:205px; width:100%;">
                <span class="text-white">Proceed to {{(isMobile$|async) ? 'Mobile' : 'Web'}}</span>
            </button>
        </div>
    </div>
    
</div>
