import {ILabelTabInterface} from "@core/interface/i-label-tab.interface";

export class ProductConstant {

    public static readonly PRODUCT_TYPE_ALL = "ALL";
    public static readonly PRODUCT_TYPE_CASH ="CASH";
    public static readonly PRODUCT_TYPE_STOCK ="STOCK";
    public static readonly PRODUCT_TYPE_ETF ="ETF";
    public static readonly PRODUCT_TYPE_UT ="UT";
    public static readonly PRODUCT_TYPE_BOND ="BOND";
    public static readonly PRODUCT_TYPE_ON_DEPO ="ON-DEPO";
    public static readonly PRODUCT_TYPE_FIXED_DEPOSIT ="FD";
    public static readonly PRODUCT_TYPE_FX = 'FX';
    public static readonly PRODUCT_TYPE_STOCK_ETF = 'STOCK / ETF';

    public static readonly PRODUCT_TYPE_FILTER_BAR_MAP : Map<string, ILabelTabInterface> = new Map([
        [this.PRODUCT_TYPE_ALL, { name: 'All', value: this.PRODUCT_TYPE_ALL }],
        [this.PRODUCT_TYPE_STOCK_ETF, { name: 'Stock/ETFs', value: this.PRODUCT_TYPE_STOCK_ETF, index: 0 },],
        [this.PRODUCT_TYPE_BOND, { name: 'Bonds', value: this.PRODUCT_TYPE_BOND, index: 1 },],
        [this.PRODUCT_TYPE_UT, { name: 'Funds', value: this.PRODUCT_TYPE_UT, index: 2 },],
        [this.PRODUCT_TYPE_FIXED_DEPOSIT, { name: 'FD', value: this.PRODUCT_TYPE_FIXED_DEPOSIT, index: 3 },],
        [this.PRODUCT_TYPE_ON_DEPO, { name: 'Overnight Deposits', value: this.PRODUCT_TYPE_ON_DEPO, index: 4 },],
    ]);


    // get filtered product type from tab value
    public static getFilteredProductTypeList(value: string): string[] {
        let filteredProductTypeList = [];
        switch (value) {
            case ProductConstant.PRODUCT_TYPE_STOCK_ETF:
                filteredProductTypeList = [ProductConstant.PRODUCT_TYPE_STOCK, ProductConstant.PRODUCT_TYPE_ETF];
                break;
            default:
                filteredProductTypeList = [value];
                break;
        }
        return filteredProductTypeList;
    }

    public static getTradeTabFromProductType(productType: string) {
        switch (productType) {
            case ProductConstant.PRODUCT_TYPE_STOCK:
            case ProductConstant.PRODUCT_TYPE_ETF:
                return ProductConstant.PRODUCT_TYPE_STOCK_ETF;

            default:
                return productType;
        }
    }

    public static getTradeTabList(hasOnDepo: boolean) {
        const tradeTabList: ILabelTabInterface[] = [];
        tradeTabList.push(ProductConstant.PRODUCT_TYPE_FILTER_BAR_MAP.get(ProductConstant.PRODUCT_TYPE_STOCK_ETF));
        tradeTabList.push(ProductConstant.PRODUCT_TYPE_FILTER_BAR_MAP.get(ProductConstant.PRODUCT_TYPE_BOND));
        tradeTabList.push(ProductConstant.PRODUCT_TYPE_FILTER_BAR_MAP.get(ProductConstant.PRODUCT_TYPE_UT));
        tradeTabList.push(ProductConstant.PRODUCT_TYPE_FILTER_BAR_MAP.get(ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT));
        if (hasOnDepo) {
            tradeTabList.push(ProductConstant.PRODUCT_TYPE_FILTER_BAR_MAP.get(ProductConstant.PRODUCT_TYPE_ON_DEPO));
        }

        tradeTabList.sort((a,b) => a.index - b.index);
        return tradeTabList;
    }

    public static getTabLabelFromTradeTab(productType: string) {
        const tradeTab = ProductConstant.getTradeTabFromProductType(productType);
        return ProductConstant.PRODUCT_TYPE_FILTER_BAR_MAP.get(tradeTab);
    }

    public static isCash(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_CASH;
    }

    public static isFx(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_FX;
    }

    public static isBond(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_BOND;
    }

    public static isEtf(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_ETF;
    }

    public static isStock(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_STOCK;
    }

    public static isFund(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_UT;
    }

    public static isFd(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_FIXED_DEPOSIT;
    }

    public static isOnDepo(productType: string) {
        return productType?.toUpperCase() === ProductConstant.PRODUCT_TYPE_ON_DEPO;
    }

    public static isStockOrEtf(productType: string) {
        return this.isStock(productType) || this.isEtf(productType);
    }

    public static isStockOrEtfOrBond(productType: string) {
        return this.isStock(productType) || this.isEtf(productType) || this.isBond(productType);
    }

    public static isCashOrFx(productType: string) {
        return this.isCash(productType) || this.isFx(productType);
    }

    public static isFdOrOnDepo(productType: string) {
        return this.isFd(productType) || this.isOnDepo(productType);
    }

    public static isEligibleStockOrEtf(productType: string) {
        return ProductConstant.PRODUCT_TYPE_STOCK_ETF.includes(productType?.toUpperCase());
    }

    public static isFundOrFd(productType: string) {
        return this.isFund(productType) || this.isFd(productType);
    }

    public static isFundOrFdOrOnDepo(productType: string) {
        return this.isFund(productType) || this.isFd(productType) || this.isOnDepo(productType);
    }

    public static isAllowAmendmentProductTypes(productType: string) {
        return this.isStockOrEtfOrBond(productType);
    }

}
