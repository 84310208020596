import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable, of} from "rxjs";
import {AuthRestService} from "@api/service/api/auth.rest.service";
import {catchError, tap} from "rxjs/operators";
import {RouteConstant} from "@constant/route.constant";
import {AuthService} from "@service/auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private authRestService: AuthRestService,
        private authService: AuthService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authRestService.checkToken().pipe(
            tap((res: any) => {
                return this.authGuardCheckHandler(res);
            }),
            catchError((error) => {
                this.errorRedirect();
                return of(false);
            })
        );
    }

    authGuardCheckHandler(res: any) {
        if (res.data.active) {
            return true;
        }
        this.errorRedirect();
        return false;
    }

    errorRedirect(): void {
        this.authService.logout();
        this.router.navigate([RouteConstant.LOGIN]);
    }

}
