import {AfterContentInit, Component, ContentChild, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {UiInputDirective} from '@modules/shared-lib/common/component-revamp/ui-input/floating-label/directive/ui-input.directive';
import {CommonModule} from '@angular/common';
import {SharedCommonModule} from '@modules/shared-lib/common/common.module';

@Component({
    selector: 'app-ml-input',
    standalone: true,
    templateUrl: './floating-label.component.html',
    styleUrls: ['./floating-label.component.less'],
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, SharedCommonModule]
})
export class FloatingLabelComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy  {
  // API
  // @Input()
  // set nzData(value: any) {
  //   this.showFloatingLabel = !!value || this.interaction;
  // }
  // @Input() nzCtrl!: string | AbstractControl;
  // @Input() nzForm!: FormGroup;

  // API
  @Input() nzData!: any;
  @Input() nzPlaceHolder!: string;
  @Input() nzWrapClassName = '';
  @Input() nzWrap = true;
  @Input() nzSize: any = 'default';

  @ContentChild(UiInputDirective) input!: UiInputDirective;

  showFloatingLabel = false;
  focused$!: Observable<boolean>;

  private interaction = false;
  private subscription!: Subscription;
  private elementSubscription!: Subscription;

  ngOnInit(): void {
    if (this.nzData) {
      this.showFloatingLabel = true;
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    // if (changes.nzCtrl && this.nzCtrl) {
    //   if (typeof (this.nzCtrl) === 'string' && this.nzForm) {
    //     const formControl: AbstractControl | null = this.nzForm.get(this.nzCtrl);

    //     if (formControl) {
    //       if (this.subscription) {
    //         this.subscription.unsubscribe();
    //       }

    //       this.subscription = formControl.valueChanges.pipe(
    //         tap((val: any) => this.showFloatingLabel = (!!val || this.interaction))
    //       ).subscribe();
    //     }
    //   }
    //   else if (this.nzCtrl instanceof AbstractControl) {
    //     if (this.subscription) {
    //       this.subscription.unsubscribe();
    //     }

    //     this.subscription = this.nzCtrl.valueChanges.pipe(
    //       tap((val: any) => this.showFloatingLabel = (!!val || this.interaction))
    //     ).subscribe();
    //   }
    // }

    this.showFloatingLabel = !!this.nzData;
  }

  ngAfterContentInit(): void {
    if (this.input) {
      this.elementSubscription = this.input.showFloatedLabel$.subscribe((show: boolean) => {
        this.interaction = show;
        this.showFloatingLabel = show;
      });
      this.focused$ = this.input.focus$;
    }
    // else {
    //   console.warn('Please attach ui-input directive to the input field. The wrapper needs selector input [ui-input] to work.');
    // }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.elementSubscription) {
      this.elementSubscription.unsubscribe();
    }
  }
}
