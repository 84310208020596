<div class="w-100 h-100 bg-black-2">
    <app-post-header></app-post-header>
    <div class="print:hidden"
         [ngClass]="{
            'py-4' : showBreadcrumb,
            'pb-4' : !showBreadcrumb,
            'container': applyContainer && !(isMobile$ | async),
            'container-fluid': applyContainerFluid && !(isMobile$ | async)
         }"
    >
        <app-post-breadcrumb *ngIf="showBreadcrumb"></app-post-breadcrumb>
        <div class="min-h-screen">
            <router-outlet></router-outlet>
        </div>
        <nz-back-top class="print:hidden" [nzTemplate]="tpl" [nzVisibilityHeight]="200"></nz-back-top>
    </div>
    <div class="card-footer print:hidden">
        <app-footer></app-footer>
    </div>
</div>

<ng-template #tpl>
    <button nz-button nzType="primary" nzSize="large">
        <i nz-icon nzType="caret-up" nzTheme="outline"></i>
    </button>
</ng-template>

