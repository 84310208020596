<div class="footer bg-black text-white">
    <div class="pt-4" [ngClass]="(isMobile$ | async)? 'px-4' : 'container'">
        <div nz-row [nzGutter]="{ xs: 24, sm: 28, md: 18, lg: 1 }" nzJustify="space-between">
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="7" [nzLg]="7" [nzXl]="6" class="mb-3 mb-md-0">
                <nz-row class="">
                    <img [src]="logoUrl" alt="" class="mh-100"/>
                </nz-row>
                <nz-row class="mt-3 text-sm">
                    Delivering the best investment products and solutions for investors and our partners.
                </nz-row>
            </div>
            <div nz-col [nzXs]="7" [nzSm]="12" [nzMd]="4" [nzLg]="3" class="mb-3 mb-md-0">
                <nz-row class="mt-3">
                    <h5 class="bg-black text-white">Company</h5>
                </nz-row>
                <nz-row class="mb-2">
                    <a class="footer-link" [routerLink]="[genRouterLink(RouteConstant?.ABOUT_US)]">About Us</a>
                </nz-row>
                <nz-row class="mb-2">
                    <a class="footer-link" [routerLink]="[genRouterLink(RouteConstant?.OUR_FUNDS)]">Our Funds</a>
                </nz-row>
                <nz-row class="mb-2">
                    <a class="footer-link" [routerLink]="[genRouterLink(RouteConstant?.CAREER)]">Career</a>
                </nz-row>
                <nz-row>
                    <a class="footer-link" [routerLink]="[genRouterLink(RouteConstant?.FAQ)]">FAQ</a>
                </nz-row>
            </div>
            <!--        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="3" class="ml-2 pl-2">-->
            <!--            <div class="container mt-2 pt-2">-->
            <!--                <h4 class="bg-black text-white mt-2 pt-2 ml-2 pl-2"> Information </h4>-->
            <!--                <nz-row class="mt-2 pt-2 ml-2 pl-2">Insights</nz-row>-->
            <!--                <nz-row class="mt-2 pt-2 ml-2 pl-2">FAQ</nz-row>-->
            <!--            </div>-->
            <!--        </div>-->
            <div nz-col [nzXs]="6" [nzSm]="12" [nzMd]="5" [nzLg]="4" [nzXl]="3" class="mb-3 mb-md-0">
                <nz-row class="mt-3">
                    <h5 class="bg-black text-white">Legal</h5>
                </nz-row>
                <nz-row class="mb-2">
                    <a class="footer-link" [routerLink]="[genRouterLink(RouteConstant?.PRIVACY)]">Privacy Policy</a>
                </nz-row>
                <nz-row class="mb-2">
                    <a class="footer-link" [routerLink]="[genRouterLink(RouteConstant?.TERMS)]">Disclaimer</a>
                </nz-row>
                <nz-row class="mb-2">
                    <a class="footer-link" href="/ifast-fm/policy/Best Execution Disclosure Statement.pdf"
                       target="_blank">Best
                        Execution
                        Policy</a>
                </nz-row>
            </div>
            <div nz-col [nzXs]="11" [nzSm]="12" [nzMd]="7" [nzLg]="7" class="">
                <nz-row class="mt-3">
                    <h5 class="bg-black text-white"><a class="footer-link" [routerLink]="[genRouterLink(RouteConstant?.CONTACT_US)]">Contact Us</a></h5>
                </nz-row>
                <ng-container *ngIf="!(isMobile$ | async)">
                    <nz-row class="mb-2" [nzGutter]="14">
                        <nz-col [nzXs]="3" [nzSm]="3" [nzXl]="2">
                            <ng-container *ngTemplateOutlet="mailIcon"></ng-container>
                        </nz-col>
                        <nz-col [nzXs]="21" [nzSm]="21" [nzXl]="22">
                            <ng-container *ngTemplateOutlet="enquiryMailing"></ng-container><br/>
                        </nz-col>
                    </nz-row>
                    <nz-row class="mb-2" [nzGutter]="14">
                        <nz-col [nzXs]="3" [nzSm]="3" [nzXl]="2">
                            <ng-container *ngTemplateOutlet="mailIcon"></ng-container>
                        </nz-col>
                        <nz-col [nzXs]="21" [nzSm]="21" [nzXl]="22">
                            <ng-container *ngTemplateOutlet="bdMailing"></ng-container>
                        </nz-col>
                    </nz-row>
                    <nz-row [nzGutter]="14">
                        <nz-col [nzXs]="3" [nzSm]="3" [nzXl]="2">
                            <ng-container *ngTemplateOutlet="phoneIcon"></ng-container>
                        </nz-col>
                        <nz-col [nzXs]="21" [nzSm]="21" [nzXl]="22">
                            <ng-container *ngTemplateOutlet="address"></ng-container>
                        </nz-col>
                    </nz-row>
                </ng-container>
                <ng-container *ngIf="(isMobile$ | async)">
                    <div class="flex d-flex items-center align-items-center flex-row mb-2">
                        <ng-container *ngTemplateOutlet="mailIcon"></ng-container>
                        <div class="ml-2">
                            <ng-container *ngTemplateOutlet="enquiryMailing"></ng-container>
                        </div>
                    </div>
                    <div class="flex d-flex items-center align-items-center flex-row mb-2">
                        <ng-container *ngTemplateOutlet="mailIcon"></ng-container>
                        <div class="ml-2">
                            <ng-container *ngTemplateOutlet="bdMailing"></ng-container>
                        </div>
                    </div>
                    <div class="flex d-flex flex-row">
                        <div>
                            <ng-container *ngTemplateOutlet="phoneIcon"></ng-container>
                        </div>
                        <div class="ml-2">
                            <ng-container *ngTemplateOutlet="address"></ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <nz-divider class="mb-0 text-black-6"></nz-divider>
</div>
<nz-footer class="bg-black text-black-5 text-xs text-center"><span>@{{updateDate}}. All Rights Reserved. Reg. No. 200000231R</span>
</nz-footer>

<ng-template #mailIcon>
    <i nz-icon nzType="mail" nzTheme="fill" class="text-primary" [ngClass]="(isMobile$ |async)? 'text-sm' : 'text-md'"></i>
</ng-template>

<ng-template #enquiryMailing>
    <a class="footer-link" href="mailto:enquiry@ifastfm.com">enquiry@ifastfm.com</a>
</ng-template>

<ng-template #bdMailing>
    <a class="footer-link" href="mailto:bd@ifastfm.com">bd@ifastfm.com</a>
</ng-template>

<ng-template #phoneIcon>
    <i nz-icon nzType="phone" nzTheme="fill" class="text-primary" [ngClass]="(isMobile$ |async)? 'text-sm' : 'text-md'"></i>
</ng-template>

<ng-template #address>
    <div class="d-flex flex-column flex flex-col">
        <span>Call (+65) 6439 3821</span>
        <span>(Weekdays: 8.30am to 5.30pm, excluding PH)</span>
    </div>
</ng-template>
