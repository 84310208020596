import {NzModalService} from 'ng-zorro-antd/modal';
import {Injectable, ViewContainerRef} from '@angular/core';
import {PopMessageInterface} from '@core/interface/pop-message.interface';
import {PopMessageBoxComponent} from '@modules/shared-lib/common/component/pop-message-box/pop-message-box.component';
import {
    PopMessageModalComponent
} from '@modules/shared-lib/common/component/pop-message-modal/pop-message-modal.component';
import {
    ConfirmationModalComponent
} from '@modules/shared-lib/common/component-revamp/confirmation-modal/confirmation-modal.component';
import {
    PopMessageModalRevampComponent
} from '@modules/shared-lib/common/component-revamp/pop-message-modal-revamp/pop-message-modal-revamp.component';
import {
    TopPopMessageModalComponent
} from '@modules/shared-lib/common/component-revamp/top-pop-message-modal/top-pop-message-modal.component';
import {Select} from '@ngxs/store';
import {GlobalState} from '@core/store';
import {Observable} from 'rxjs';
import {
    TopPopMessageModalPreComponent
} from "@modules/shared-lib/common/component/top-pop-message-modal-pre/top-pop-message-modal-pre.component";

@Injectable({
    providedIn: 'root',
})
export class PopMessageService {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    isMobile: boolean = false;
    constructor(
        private modal: NzModalService,
    ) {
        this.isMobile$.subscribe((isMobile: boolean) => {
            this.isMobile = isMobile;
        })
    }

    // Pre Login pop message modal
    openConfirmationModal( viewContainerRef: ViewContainerRef, popMessage: PopMessageInterface): Promise<any> {
        const modal = this.modal.create({
            nzWrapClassName: 'basic-ui-modal grey-modal confirmation-modal',
            nzCentered: true,
            nzFooter: null,
            nzClosable: false,
            nzContent: PopMessageBoxComponent,
            nzMaskClosable: popMessage.allowOutsideClick,
            nzKeyboard: popMessage.allowEscapeKey,
            // nzZIndex: 50,
            // nzStyle: { width: '30%', maxWidth: '50%', 'padding-bottom': '0' },
            nzViewContainerRef: viewContainerRef,
            nzComponentParams: popMessage
        });

        return new Promise<boolean>(resolve => {
            modal.afterClose.subscribe(result => {
                return resolve(result);
            });
        });
    }

    // Open pop out on a new ViewContainerRef
    // Destroying original view/modal will not destroy this modal
    openNewConfirmationModal(popMessage: PopMessageInterface): Promise<any> {
        return this.openConfirmationModal(undefined, popMessage);
    }

    openPopUpModal( viewContainerRef: ViewContainerRef, popMessage: PopMessageInterface): Promise<any> {
        const modal = this.modal.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal pop-message-modal grey-modal' : 'basic-ui-modal pop-message-modal grey-modal w650-screen',
            nzCentered: true,
            nzFooter: null,
            nzClosable: false,
            nzContent: PopMessageModalComponent,
            nzMaskClosable: popMessage.allowOutsideClick,
            nzKeyboard: popMessage.allowEscapeKey,
            // nzZIndex: 50,
            // nzStyle: { width: '30%', maxWidth: '50%', 'padding-bottom': '0' },
            nzViewContainerRef: viewContainerRef,
            nzComponentParams: popMessage
        });

        return new Promise<boolean>(resolve => {
            modal.afterClose.subscribe(result => {
                return resolve(result);
            });
        });
    }

    openNewPopUpModal(popMessage: PopMessageInterface): Promise<any> {
        return this.openPopUpModal(undefined, popMessage);
    }

    openNewPopUpErrorModal(message: string): Promise<any> {
        return this.openNewPopUpModal({
            titleText: 'Error',
            bodyText: message,
            confirmBtn: true,
            confirmBtnHtml: 'Ok',
            type: 'error',
        });
    }

    openTopPopMessageModalPreLoginModal(popMessage: PopMessageInterface, autoCloseDuration: number = 3000) {
        const modal = this.modal.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal top-message-modal' :  'basic-ui-modal top-message-modal w458-screen',
            nzCentered: false,
            nzFooter: null,
            nzClosable: false,
            nzContent: TopPopMessageModalPreComponent,
            nzMaskClosable: popMessage.allowOutsideClick,
            nzKeyboard: popMessage.allowEscapeKey,
            nzViewContainerRef: null,
            nzComponentParams: popMessage
        });

        // Auto-close after specified duration
        if (autoCloseDuration > 0) {
            setTimeout(() => {
                modal.close();  // Close the modal after the specified duration
            }, autoCloseDuration);
        }

        return new Promise<boolean>(resolve => {
            modal.afterClose.subscribe(result => {
                return resolve(result);
            });
        });
    }

    // post login pop message modal
    openPostLoginConfirmationModal(popMessage: PopMessageInterface): Promise<any> {
        const modal = this.modal.create({
            nzWrapClassName: 'basic-ui-modal grey-modal confirmation-modal',
            nzCentered: true,
            nzFooter: null,
            nzClosable: false,
            nzContent: ConfirmationModalComponent,
            nzMaskClosable: popMessage.allowOutsideClick,
            nzKeyboard: popMessage.allowEscapeKey,
            // nzZIndex: 50,
            // nzStyle: { width: '30%', maxWidth: '50%', 'padding-bottom': '0' },
            nzViewContainerRef: null,
            nzComponentParams: popMessage
        });

        return new Promise<boolean>(resolve => {
            modal.afterClose.subscribe(result => {
                return resolve(result);
            });
        });
    }

    openPostLoginPopUpModal(popMessage: PopMessageInterface): Promise<any> {
        const modal = this.modal.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal pop-message-modal grey-modal' : 'basic-ui-modal pop-message-modal grey-modal w650-screen',
            nzCentered: true,
            nzFooter: null,
            nzClosable: false,
            nzContent: PopMessageModalRevampComponent,
            nzMaskClosable: popMessage.allowOutsideClick,
            nzKeyboard: popMessage.allowEscapeKey,
            // nzZIndex: 50,
            // nzStyle: { width: '30%', maxWidth: '50%', 'padding-bottom': '0' },
            nzViewContainerRef: null,
            nzComponentParams: popMessage
        });

        return new Promise<boolean>(resolve => {
            modal.afterClose.subscribe(result => {
                return resolve(result);
            });
        });
    }

    openPostLoginPopUpErrorModel(title: string, message: string) {
        return this.openPostLoginPopUpModal({
            type: 'error',
            titleText: title,
            bodyText: message,
            confirmBtn: true,
            confirmBtnHtml: 'Ok',
        });
    }

    openTopPopMessageModal(popMessage: PopMessageInterface, autoCloseDuration: number = 3000) {
        const modal = this.modal.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal top-message-modal' :  'basic-ui-modal top-message-modal w458-screen',
            nzCentered: false,
            nzFooter: null,
            nzClosable: false,
            nzContent: TopPopMessageModalComponent,
            nzMaskClosable: popMessage.allowOutsideClick,
            nzKeyboard: popMessage.allowEscapeKey,
            nzViewContainerRef: null,
            nzComponentParams: popMessage
        });

        // Auto-close after specified duration
        if (autoCloseDuration > 0) {
            setTimeout(() => {
                modal.close();  // Close the modal after the specified duration
            }, autoCloseDuration);
        }

        return new Promise<boolean>(resolve => {
            modal.afterClose.subscribe(result => {
                return resolve(result);
            });
        });
    }

    openErrorTopMessageModal(errorMessage: string) {
        this.openTopPopMessageModal({
            type: 'error',
            titleText: 'Error',
            bodyText: errorMessage
        }).then();
    }
}
