import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {GlobalState} from '@core/store';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {AppConstant} from '@constant/app.constant';
import {SubHandlingService} from '@modules/shared-lib/common/service/subscription-handling.service';
import {PresessionModel} from '@model/account/fm-client.model';
import {AuthService} from '@service/auth/auth.service';
import {PopMessageService} from '@service/pop-message.service';
import {AuthState} from '@store/state/auth.state';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LocalStorageService} from '@service/local-storage.service';
import {Router} from '@angular/router';
import {
    ForgotUsernameModalComponent
} from '@modules/auth/component/forgot-username-modal/forgot-username-modal.component';
import {
    ForgotPasswordModalComponent
} from '@modules/auth/component/forgot-password-modal/forgot-password-modal.component';
import {AuthConstant} from "@constant/auth.constant";

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [SubHandlingService]
})
export class LoginComponent implements OnInit {

    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
    isMobile: boolean = false
    form: FormGroup;
    visiblePwd = false;
    rememberMeOption = false;
    loginLoading = false;

    constructor(
        private authService: AuthService,
        private fb: FormBuilder,
        private subHandlingService: SubHandlingService,
        private store: Store,
        private localStorageService: LocalStorageService,
        private nzModalService: NzModalService,
        private router: Router,
        private popMessageService: PopMessageService,
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        this.isMobile$.subscribe((isMobile: boolean) => {
            this.isMobile = isMobile;
        })
        const rememberMe = this.localStorageService.get('rememberMe');
        if (rememberMe === AppConstant.YES_FLAG) {
            const username = this.localStorageService.get('username');
            this.rememberMeOption = true;
            if (username) {
                this.form.get('username').setValue(username);
            }
        }
    }

    initForm() {
        this.form = this.fb.group({
            username: [null, [Validators.required]],
            password: [null, [Validators.required]]
        });
    }

    onChangeUsername(event: any) {
        this.form.get('username').setValue(event.toUpperCase());
    }

    login() {
        if (this.form.valid) {
            this.loginLoading = true;
            const username = this.form.get('username').value;
            const password = this.form.get('password').value;
            let errorMessage = '';
            this.subHandlingService.once(
                this.authService.getPresession(AuthConstant.PRESESSION_TYPE_LOGIN).pipe(tap((res: PresessionModel) => {
                    if (res) {
                        const rsa = this.authService.getNewRsaEngineWithKeys(res.publicKey, res.sid, res.randomNumber);
                        const rpin = rsa.encryptPIN1(password);

                        this.subHandlingService.once(
                            this.authService.login(username, rpin, res.randomNumber, res.sid).pipe(tap((loginRes) => {
                                console.log('login res', loginRes);
                                if (loginRes && loginRes.statusCode === 'OK' && loginRes.body) {
                                    this.authService.loginSuccessHandler(loginRes);
                                    if (this.rememberMeOption) {
                                        this.localStorageService.set(LocalStorageService.REMEMBER_ME, AppConstant.YES_FLAG);
                                        this.localStorageService.set(LocalStorageService.USERNAME, username);
                                    } else {
                                        this.localStorageService.set(LocalStorageService.REMEMBER_ME, AppConstant.NO_FLAG);
                                        this.localStorageService.remove(LocalStorageService.USERNAME);
                                    }
                                } else {
                                    if (loginRes.body.errorMessage === '1001') {
                                        // INVALID_CREDENTIAL
                                        errorMessage = 'Login Failed, Please ensure the username and password is valid.';
                                    }

                                    if (loginRes.body.errorMessage === '1000') {
                                        // ACCOUNT_NOT_FOUND
                                        errorMessage = 'Login Failed, Account is not found';
                                    }

                                    if (loginRes.body.errorMessage === '1002') {
                                        // INVALID_PASSWORD
                                        errorMessage = 'Login Failed, Please ensure the username and password is valid.';
                                    }

                                    if (loginRes.body.errorMessage === '1003') {
                                        // INACTIVE_USER
                                        errorMessage = 'Login Failed, Account has been deactivated.';
                                    }

                                    if (loginRes.body.errorMessage === '1200') {
                                        // E2EE error
                                        errorMessage = 'Some thing wrong in encryption/verification. Please contact IT.';
                                    }

                                    if (loginRes.body.errorMessage === '1201') {
                                        // E2EE error
                                        errorMessage = 'Missing encryption details. Please contact IT.';
                                    }

                                    if (loginRes.body.errorMessage === '1400') {
                                        // E2EE error
                                        errorMessage = 'Too frequent access. Please try again later.';
                                    }
                                }
                                if (errorMessage) {
                                    this.openErrorLoginModal(errorMessage);
                                }
                                this.loginLoading = false;
                            }, error => {
                                console.log('login error', error);
                                errorMessage = 'LOGIN_ERROR';
                                this.openErrorLoginModal(errorMessage);
                                this.loginLoading = false;
                            })
                        ));
                    } else {
                        console.log('login error');
                        errorMessage = 'LOGIN_ERROR';
                        this.openErrorLoginModal(errorMessage);
                        this.loginLoading = false;
                    }
                }, error => {
                    console.log('error', error);
                    errorMessage = error;
                    this.openErrorLoginModal(errorMessage);
                    this.loginLoading = false;
                }))
            );
        }
    }

    forgotUsername() {
        this.nzModalService.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal grey-modal' : 'basic-ui-modal grey-modal w650-screen',
            nzCentered: true,
            nzClosable: true,
            nzMaskClosable: false,
            nzContent: ForgotUsernameModalComponent
        });
    }

    forgotPassword() {
        this.nzModalService.create({
            nzWrapClassName: this.isMobile? 'basic-ui-modal' : 'basic-ui-modal w650-screen',
            nzCentered: true,
            nzClosable: true,
            nzMaskClosable: false,
            nzContent: ForgotPasswordModalComponent
        });
    }

    openErrorLoginModal(errorMessage: string) {
        this.popMessageService.openNewPopUpModal({
            titleText: 'Error',
            bodyText: errorMessage,
            confirmBtn: true,
            confirmBtnHtml: 'Ok',
            type: 'error',
        }).then();

    }

}
