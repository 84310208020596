import {Pipe, PipeTransform} from '@angular/core';
@Pipe({name: 'filterList'})
export class FilterListPipe implements PipeTransform {
    transform(list: any[], key: string, value: any): any[] {
        if (key) {
            return list.filter(item => item[key] === value);
        }
        return list;
    }
}
