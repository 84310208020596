import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyPosition'
})
export class CurrencyPositionPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value; // Return if the value is falsy

        const parts = value.split(' '); // Split the string by space
        if (parts.length !== 2) return value; // Ensure there are exactly 2 parts

        const amount = parts[0]; // First part is the amount
        const currency = parts[1]; // Second part is the currency

        return `${currency} ${amount}`; // Return in "Currency Amount" format
    }
}
