<app-safe-area></app-safe-area>
<div class="w-100 h-100 bg-black-2">
    <app-main-layout-header [isPostLoginMenu]="false"></app-main-layout-header>
    <div id="content">
        <router-outlet></router-outlet>
        <nz-back-top class="d-print-none" [nzTemplate]="tpl" [nzVisibilityHeight]="200"></nz-back-top>
    </div>
<!--        <app-live-chat></app-live-chat>-->
    <div class="card-footer d-print-none">
        <app-main-layout-footer></app-main-layout-footer>
    </div>
</div>

<ng-template #tpl>
    <button nz-button nzType="primary" nzSize="large">
        <i nz-icon nzType="caret-up" nzTheme="outline"></i>
    </button>
</ng-template>

