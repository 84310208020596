export class PageConstant {
  public static readonly PAGE_TITLE: string = 'iFAST Fund Management';

    // Pages
    public static readonly OUR_FUNDS: string = 'Our Funds';
    public static readonly ABOUT_US: string = 'About Us';
    public static readonly MEDIA: string = 'Media';
    public static readonly INSIGHTS: string = 'Insights';
    public static readonly FAQ: string = 'FAQ';
    public static readonly PARTNERSHIP: string = 'Partnership';
    public static readonly CONTACT_US: string = 'Contact Us';
    public static readonly PLACEHOLDER: string = 'Not Found';
    public static readonly ALL_DIST: string = 'Distributor';
    public static readonly DASHBOARD: string = 'Dashboard';
    public static readonly HOME: string = 'Home';

    // Our Funds
    public static readonly FACTSHEET: string = 'Factsheet';
    public static readonly FUND_FACTSHEET: string = 'Fund Factsheet';

    // PRIVACY
    public static readonly PRIVACY: string = 'Privacy Policy';
    public static readonly TERMS: string = 'Terms and Conditions';
    public static readonly DISCLAIMER: string = 'Disclaimer';
    public static readonly CAREER: string = 'Career';
    public static readonly BEST_EXECUTION_POLICY: string = 'Best Execution Policy';

    // portfolio management
    public static readonly PORTFOLIO_MANAGEMENT: string = 'Portfolio Management and Execution';
    public static readonly PORTFOLIO_TRANSACTION: string = 'Portfolio Transaction';
    public static readonly FUND_PROFILE: string = 'Fund Profile';
    public static readonly ORDER_PAD: string = 'Order Pad';
    public static readonly PRODUCT_ORDERPAD: string = 'Product OrderPad';
    public static readonly ORDER_PAD_SUMMARY: string = 'Order Pad Summary';
    public static readonly HISTORICAL_ORDERS: string = 'Historical Orders';

    // Approvals
    public static readonly APPROVAL: string = 'Approval';
    public static readonly APPROVALS: string = 'Approvals';
    public static readonly MY_APPROVALS: string = 'My Approvals';
    public static readonly PROCESSING: string = 'Processing';
    public static readonly HISTORICAL: string = 'Historical';
    public static readonly REJECTED_VOIDED: string = 'Rejected & Voided';
    public static readonly PENDING_ORDERS: string = 'Pending Orders';
    public static readonly ORDER_APPROVAL: string = 'Order Approval';
    public static readonly LIVE_ORDER_APPROVAL: string = 'Live Order Approval';
    public static readonly ORDER: string = 'Order';
    public static readonly LIVE_ORDER: string = 'Live Order';

    // Report
    public static readonly REPORT: string = 'Report';

    // Notification
    public static readonly NOTIFICATION: string = 'Notification';

    // Profile
    public static readonly PROFILE_SETTINGS: string = 'Profile Settings';
    public static readonly MY_PROFILE: string = 'My Profile';
    public static readonly CHANGE_PASSWORD: string = 'Change Password';
    public static readonly LOGOUT: string = 'Logout';

}
