import {AxisTypeValue, ChartOptions, LangOptions, LegendOptions} from 'highcharts';
import {ColorConstant} from './highchart-color.constant';


export class HighchartsConstant {
  // AxisTypeValue
  public static readonly LINEAR: AxisTypeValue = 'linear';
  public static readonly LOGARITHMIC: AxisTypeValue = 'logarithmic';
  public static readonly DATETIME: AxisTypeValue = 'datetime';
  public static readonly CATEGORY: AxisTypeValue = 'category';
  public static readonly TREEGRID: AxisTypeValue = 'treegrid';


  // Charts
  public static readonly PRIMARY_COLOR: string = '#004f6f';

  // Legends
  public static readonly LEGEND_STYLE: LegendOptions = {
    itemStyle: {
      fontFamily: 'Lato'
    }

  };

  // Zoom
  public static readonly ZOOM_OPTIONS: ChartOptions = {
    zoomType: 'x',
    resetZoomButton: {
      position: {
        align: 'center'
      },
      theme: {
        r: '6px',
        states: {
          hover: {
            fill: '#012c5d',
            style: {
              color: 'white'
            }
          }
        }
      }
    }
  };

  // Language
  public static readonly LANG: LangOptions = {
    resetZoom: 'Reset Zoom'
  };

  // PLOT_COLORS
  public static PLOT_COLORS: string[] = ColorConstant.HIGHCHART_COLOR;
  public static PLOT_AREA_COLORS: string[] = ColorConstant.AREAFILL_COLOR;

  public static DASHBOARD_PLOT_COLORS: string[] = ColorConstant.DASHBOARD_CHART_COLOR;
  public static DASHBOARD_AREA_COLORS: string[] = ColorConstant.DASHBOARD_AREAFILL_COLOR;

  // PORTFOLIO_PLANNER_PLOT_COLORS
  public static PORTFOLIO_PLANNER_PLOT_COLORS: string[] = ColorConstant.PORTFOLIO_PLOT_COLOR;

  // CrossHairs
  public static readonly ENABLE_CROSSHAIR: object = {
    crosshair: true
  };

  // Exports
  public static readonly EXPORT_CONFIG: object = {
    enabled: true,
    formAttributes: {
      target: '_blank'
    },
    buttons: {
      contextButton: {
        menuItems: ['printChart', 'downloadPDF', 'downloadJPEG']
      }
    }
  };
  // Credits
  public static readonly CREDIT_TEXT: string = 'iFAST';
  public static readonly CREDIT_HREF: string = '';
  public static readonly CREDIT_CONFIG: object = {
    href: HighchartsConstant.CREDIT_HREF,
    text: HighchartsConstant.CREDIT_TEXT,
    position: {
      align: 'center',
      verticalAlign: 'middle',
      x: 0
    },
    style: {
      'font-size': '3.0rem',
      color: 'rgba(0, 0, 0, 0.3)',
      'z-index': '-1000',
      width: '100%',
      'text-align': 'center',
      'font-weight': '600',
      'font-family': 'Lato'
    }
  };

}
