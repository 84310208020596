export class AppConstant {
    // URL
    public static readonly TRIAL: string = 'https://secure.ifastfm.com/ifast-fm';

    // Platform
    public static readonly WEB: string = 'WEB';
    public static readonly ANDROID: string = 'Android';
    public static readonly IOS: string = 'iOS';

    // General
    public static readonly LOCALE_EN: string = 'en';
    public static readonly DATE_FORMAT: string = 'dd MMM yyyy';
    public static readonly RESPONSE_SUCCESS: string = 'SUCCESS';
    public static readonly RESPONSE_FAILED: string = 'FAILED';
    public static readonly YES_FLAG: string = 'Y';
    public static readonly NO_FLAG: string = 'N';
    public static readonly NO_DP: string = '1.0-0';
    public static readonly TWO_DP: string = '1.2-2';
    public static readonly THREE_DP: string = '1.3-3';
    public static readonly FOUR_DP: string = '1.4-4';
    public static readonly DEFAULT_NULL_DISPLAY: string = 'N/A';
    public static readonly EMPTY_STRING: string = '';
    public static readonly DASH: string = '-';
    public static readonly TBD: string = 'TBD';

    // Printing
    public static PR_REPLACE = 'print-replace';
    public static PR_ADD = 'print-add';
    public static PR_REMOVE = 'print-remove';
    public static PR_REMOVE_ALL = 'print-remove-all-classes';
    public static PR_CHART = 'print-chart';
    public static ALL_ENTRIES = 999999;

    // file MIME type
    public static readonly FILE_MIME_TYPE = {
        PNG: 'image/png',
        JPG: 'image/jpeg',
        GIF: 'image/gif',
        PDF: 'application/pdf',
        ZIP: 'application/zip',
        XLS: 'application/vnd.ms-excel',
        XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        JSON: 'application/json',
        CSV: 'text/csv',
        TXT: 'text/plain',
        DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        DOC: 'application/msword',
        PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        PPT: 'application/vnd.ms-powerpoint'
    };
}
