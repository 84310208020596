import {Component, OnInit} from '@angular/core';
import {Select} from "@ngxs/store";
import {GlobalState} from "@core/store";
import {Observable} from "rxjs";
import {RouteConstant} from "@constant/route.constant";
import {genRouterLink} from "@util/route";

@Component({
    selector: 'app-footer',
    templateUrl: './footer-revamp.component.html',
    styleUrls: ['./footer-revamp.component.less']
})
export class FooterRevampComponent implements OnInit {

    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    readonly routeConstant = RouteConstant;
    readonly genRouterLink = genRouterLink;
    footerNav: any[] = [
        {
            title: 'Company',
            nav: [
                {title: 'About Us', url: genRouterLink(RouteConstant.ABOUT_US)},
                {title: 'Our Funds', url: genRouterLink(RouteConstant.OUR_FUNDS)},
                {title: 'Career', url: genRouterLink(RouteConstant.CAREER)},
                {title: 'FAQ', url: genRouterLink(RouteConstant.FAQ)},
            ]
        },
        {
            title: 'Legal',
            nav: [
                {title: 'Privacy Policy', url: genRouterLink(RouteConstant.PRIVACY)},
                {title: 'Disclaimer', url: genRouterLink(RouteConstant.TERMS)},
                {title: 'Best Execution Policy', url: '/ifast-fm/policy/Best Execution Disclosure Statement.pdf', isCustomUrl: true}
            ]
        }
    ];

    constructor() {
    }

    ngOnInit(): void {
    }
}
