import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ShareModalComponent
} from "@modules/shared-lib/common/component-revamp/article/share-article-modal/share-modal.component";
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import {NzImageModule} from "ng-zorro-antd/image";
import {faFacebookF, faLinkedinIn, faTelegram, faWhatsapp, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzFormModule} from "ng-zorro-antd/form";

const icons: any = [
    faLink,
    faXTwitter,
    faTelegram,
    faWhatsapp,
    faFacebookF,
    faLinkedinIn,
];

const shareProp = {
    copy: {
        icon: ['fas', 'link']
    },
    twitter: {
        icon: ['fab', 'x-twitter']
    },
    telegram: {
        icon: ['fab', 'telegram']
    },
};


@NgModule({
    declarations: [
        ShareModalComponent,
    ],
    imports: [
        CommonModule,
        ShareButtonsModule.withConfig({
            prop: shareProp,
            autoSetMeta: false,
        }),
        NzImageModule,
        NzModalModule,
        NzFormModule,
    ]
})
export class ShareModalModule {

    constructor(
        private iconLibrary: FaIconLibrary,
    ) {
        this.iconLibrary.addIcons(...icons);
    }
}
