<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
          (nzAfterClose)="handleClose()" (nzOnCancel)="handleClose()" nzMaskClosable="true" [nzFooter]="Footer">
    <div class="d-flex flex-column">
        <nz-row nzAlign="middle" nzJustify="space-between">
            <nz-col [nzLg]="4" [nzMd]="12" [nzSm]="12" [nzXs]="12">
                <div class="text-center mb-3">
                    <a [href]="constructedFacebookLink" target="_blank" rel="noopener">
                        <img src="assets/images/social-media/facebook.svg" alt="Facebook"/>
                    </a>
                    <div class="text-sm py-2">Facebook</div>
                </div>
            </nz-col>
            <nz-col [nzLg]="4" [nzMd]="12" [nzSm]="12" [nzXs]="12">
                <div class="text-center mb-3">
                    <a [href]="constructedTwitterLink" target="_blank" rel="noopener">
                        <img src="assets/images/social-media/twitter.svg" alt="Facebook"/>
                    </a>
                    <div class="text-sm py-2">Twitter</div>
                </div>
            </nz-col>
            <nz-col [nzLg]="4" [nzMd]="12" [nzSm]="12" [nzXs]="12">
                <div class="text-center mb-3">
                    <a [href]="constructedTelegramLink" target="_blank" rel="noopener">
                        <img src="assets/images/social-media/telegram.svg" alt="Facebook"/>
                    </a>
                    <div class="text-sm py-2">Telegram</div>
                </div>
            </nz-col>
            <nz-col [nzLg]="4" [nzMd]="12" [nzSm]="12" [nzXs]="12">
                <div class="text-center mb-3">
                    <a [href]="constructedWhatsappLink" target="_blank" rel="noopener"
                       data-action="share/whatsapp/share">
                        <img src="assets/images/social-media/whatapps.svg" alt="Facebook"/>
                    </a>
                    <div class="text-sm py-2">Whatapps</div>
                </div>
            </nz-col>
            <nz-col [nzLg]="4" [nzMd]="12" [nzSm]="12" [nzXs]="12">
                <div class="text-center mb-3">
                    <a [href]="constructedEmailLink" target="_blank" rel="noopener">
                        <img src="assets/images/social-media/email.svg" alt="Facebook"/>
                    </a>
                    <div class="text-sm py-2">Email</div>
                </div>
            </nz-col>
        </nz-row>
        <nz-row>
            <nz-input-group [nzSuffix]="suffixTemplate" class="general-search">
                <input #copyClipBoard nz-input readonly disabled [value]="getClipboardLink"/>
            </nz-input-group>
        </nz-row>
    </div>
</nz-modal>

<ng-template #suffixTemplate>
    <a (click)="shareLink()" class="mb-2 text-primary text-sm mx-2 my-2">
        Copy
    </a>
</ng-template>

<ng-template #modalTitle>
    <span class="font-weight-bold">Share To</span>
</ng-template>

<ng-template #Footer></ng-template>
