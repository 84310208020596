import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DynamicCssLoaderService {
    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    // Method to preload a CSS file
    preloadCss(url: string, id: string): void {
        if (!document.getElementById(id)) {
            const linkElement = this.renderer.createElement('link');
            this.renderer.setAttribute(linkElement, 'id', id);
            this.renderer.setAttribute(linkElement, 'rel', 'preload');
            this.renderer.setAttribute(linkElement, 'as', 'style');
            this.renderer.setAttribute(linkElement, 'href', url);
            this.renderer.appendChild(this.renderer.selectRootElement('head', true), linkElement);
        }
    }

    // Method to load a preloaded CSS file or inject a new one if not preloaded
    loadCss(url: string, id: string): void {
        // select root element will hit null pointer, must use document get element id
        const element = document.getElementById(id);
        if (element) {
            const existingLinkElement = this.renderer.selectRootElement(`#${id}`, true) as HTMLLinkElement;
            if (existingLinkElement && existingLinkElement.rel === 'preload') {
                // If the CSS file has been preloaded, change its rel attribute to 'stylesheet'
                this.renderer.setAttribute(existingLinkElement, 'rel', 'stylesheet');
            } else if (!existingLinkElement) {
                // If the CSS file has not been preloaded, inject a new link element
                this.createLoadCss(url, id);
            }
        } else {
            this.createLoadCss(url, id);
        }
    }

    private createLoadCss(url: string, id: string): void {
        const linkElement = this.renderer.createElement('link');
        this.renderer.setAttribute(linkElement, 'id', id);
        this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
        this.renderer.setAttribute(linkElement, 'href', url);
        this.renderer.appendChild(this.renderer.selectRootElement('head', true), linkElement);
    }

    removeCss(id: string): void {
        const existingLinkElement = this.renderer.selectRootElement(`#${id}`, true);
        if (existingLinkElement) {
            this.renderer.removeChild(this.renderer.selectRootElement('head', true), existingLinkElement);
        }
    }
}
