import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    // storage key constant
    public static readonly ORDER_PAD = 'orderPad';
    public static readonly REMEMBER_ME = 'rememberMe';
    public static readonly USERNAME = 'username';
    public static readonly PREV_URL = 'prev_url';


    private store = window.localStorage;

    set(key: string, data: any) {
        if (typeof data !== 'string') {
            let cache: any[];
            data = JSON.stringify(data);
            cache = null;
        }

        this.store.setItem(key, data);
    }

    get(key: string) {
        const item = this.store.getItem(key);

        if (item === null) {
            return null;
        }

        try {
            return JSON.parse(item);
        } catch (_) {
            return item;
        }
    }

    remove(key: string) {
        this.store.removeItem(key);
    }

    removeMany(...keys: Array<string>) {
        keys.forEach(key => this.store.removeItem(key));
    }

    getCircularReplacer = () => {
        // dont use this replacer, only use for debugging circular structure purposes
        // it will remove items not in circular structure as long as the item object is the same
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    // console.log('Cyclic Replacing (Please Check): ', key , ' : ', value);
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    }
}
