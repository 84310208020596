import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-ui-input-wrapper',
  standalone: true,
  templateUrl: './ui-input-wrapper.component.html',
  styleUrls: ['./ui-input-wrapper.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class UiInputWrapperComponent implements OnInit {
  ngOnInit(): void {
  }

}
