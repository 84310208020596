import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PostBreadcrumbService {
    private showBreadcrumb: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private applyContainer: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private applyContainerFluid: BehaviorSubject<boolean> = new BehaviorSubject(false);

    showBreadcrumbChange$ = this.showBreadcrumb.asObservable();
    applyContainerChange$ = this.applyContainer.asObservable();
    applyContainerFluidChange$ = this.applyContainerFluid.asObservable();

    constructor() {
    }

    updateShowBreadcrumb(show: boolean) {
        this.showBreadcrumb.next(show);
    }

    updateApplyContainer(show: boolean) {
        if (show) {
            this.updateApplyContainerFluid(false);
        }
        this.applyContainer.next(show);
    }

    updateApplyContainerFluid(show: boolean) {
        if (show) {
            this.updateApplyContainer(false);
        }
        this.applyContainerFluid.next(show);
    }

    defaultShowBreadcrumb() {
        this.showBreadcrumb.next(true);
    }

    defaultApplyContainer() {
        this.applyContainer.next(true);
    }

    defaultApplyContainerFluid() {
        this.applyContainerFluid.next(false);
    }
}
