import {Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Store } from '@ngxs/store';
import { concatMap, tap, delay, map } from 'rxjs/operators';
import { Subscription, of, Observable, from, fromEvent } from 'rxjs';
import { UpdatePrint } from '@core/store';
import { AppConstant } from 'src/app/constant/app.constant';
import domtoimage from 'dom-to-image';

@Component({
    selector: 'app-shared-print-button',
    templateUrl: './print-button.component.html',
    host: {

    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintButtonComponent implements OnDestroy {
    @Input() printingId: string;
    @Input() swapClasses: any = {};
    @Input() addClasses = false;
    @Input() removeClasses = false;
    @Input() chart = false;
    @Input() readyToPrint = true;
    @Input() nzClass: string;

    @Output() isPrintingClicked = new EventEmitter<any>();
    @Output() isPrintingDone = new EventEmitter<any>();

    loading = false;

    constructor(private store: Store, private cdRef: ChangeDetectorRef) {

    }

    ngOnDestroy(): void {
        const printContainer = document.getElementById('print-root');

        while (printContainer.firstChild) {
            printContainer.removeChild(printContainer.firstChild);
        }
    }

    print() {
        this.isPrintingClicked.emit();

        const printContainer = document.getElementById('print-root');

        while (printContainer.firstChild) {
            printContainer.removeChild(printContainer.firstChild);
        }

        this.loading = true;
        this.cdRef.markForCheck();

        const subs: Subscription = this.store.dispatch(new UpdatePrint(true)).pipe(
            concatMap(() => {
                const observable: Observable<any> = this.chart ? this.convertImages() : of(null);

                return observable.pipe(delay(100), map((images?: Array<any>) => {
                    if (this.removeClasses || this.chart || this.addClasses || (this.swapClasses && Object.keys(this.swapClasses).length)) {
                        return this.processDOM(images);
                    }

                    return document.getElementById(this.printingId).innerHTML;
                }), tap((printContent: string) => {
                    this.loading = false;
                    this.cdRef.markForCheck();
                    const appRoot = document.getElementById('app-root');
                    if (appRoot) {
                        appRoot.className = 'd-print-none print:hidden';
                    }

                    printContainer.innerHTML = printContent;

                    setTimeout(() => {
                        window.print();
                    }, 300);
                }));
            }),
            concatMap(() => this.store.dispatch(new UpdatePrint())),
            concatMap(() => fromEvent(window, 'afterprint').pipe(tap(() => document.getElementById('app-root').className = '')))
        ).subscribe(() => {
            if (subs) {
                this.isPrintingDone.emit();
                subs.unsubscribe();
            }
        });
    }

    @HostListener('document:keydown', ['$event'])
    keydown(e: KeyboardEvent): void {
        if (!e.isTrusted) {
            return;
        }

        if ((e.metaKey || e.ctrlKey) && e.keyCode === 80 && this.printingId) {
            e.preventDefault();
            this.print();
        }
    }

    private convertImages(): Observable<any> {
        const highchartElements: NodeList = document.querySelectorAll(`#${this.printingId} highcharts-chart.${AppConstant.PR_CHART}`);
        const promises = [];

        highchartElements.forEach(element => {
            promises.push(domtoimage.toJpeg(element, {
                quality: 1,
                bgcolor: 'white'
            }));
        });

        return from(Promise.all(promises));
    }

    private processDOM(chartImages?: any) {
        const node: any = document.getElementById(this.printingId).cloneNode(true);
        const klass = this;

        if (this.swapClasses && Object.keys(this.swapClasses).length) {
            const elements: NodeList = node.querySelectorAll(`.${AppConstant.PR_REPLACE}`);

            elements.forEach((element: Element) => {
                element.className = replaceClasses(element.className);
            });
        }

        if (this.removeClasses) {
            const elements: NodeList = node.querySelectorAll(`.${AppConstant.PR_REMOVE}`);

            elements.forEach((element: Element) => {
                if (element.className.search(AppConstant.PR_REMOVE_ALL) > -1) {
                    element.className = '';
                } else {
                    element.className = removeClasses(element.className);
                }
            });
        }

        if (this.addClasses) {
            const elements: NodeList = node.querySelectorAll(`.${AppConstant.PR_ADD}`);

            elements.forEach((element: Element) => {
                element.className = addClasses(element.className);
            });
        }

        if (this.chart && chartImages) {
            const highchartElements: NodeList = node.querySelectorAll(`highcharts-chart.${AppConstant.PR_CHART}`);

            highchartElements.forEach((element: Node, index: number) => {
                const img = document.createElement('img');
                img.setAttribute('src', chartImages[index]);
                img.setAttribute('alt', 'chart image');
                img.className = 'img-fluid print-chart-image no-process';

                element.parentNode.replaceChild(img, element);
            });
        }

        function replaceClasses(className: string): string {
            if (checkEmpty(className)) {
                return '';
            }

            let temp: string = className.slice();

            Object.keys(klass.swapClasses).forEach((key: string) => {
                temp = temp.replace(key, klass.swapClasses[key]);
            });

            return temp.replace(AppConstant.PR_REPLACE, '');
        }

        function addClasses(className: string): string {
            return checkEmpty(className) ? '' : className.slice().replace(/__(.*?)__/ig, '$1').replace(AppConstant.PR_ADD, '').replace('  ', ' ');
        }

        function removeClasses(className: string): string {
            if (checkEmpty(className)) {
                return '';
            }

            const regex = /---(.*?)---/g;
            const result = className.match(regex);

            if (result === null || (result && !Array.isArray(result))) {
                return className;
            }

            let classNameResult: string = className.slice();

            result.forEach((res: string) => {
                classNameResult = classNameResult.replace(res, '');
                res = res.replace(/---/g, '');
                classNameResult = classNameResult.replace(res, '');
            });

            return classNameResult.replace(AppConstant.PR_REMOVE, '');
        }

        function checkEmpty(className: string) {
            return !className || (className && className.length === 0);
        }

        return node.innerHTML;
    }

    clickButton() {
        this.print();
    }
}
