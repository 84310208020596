import {Component, Input, OnInit, Output} from '@angular/core';
import {SmallChartSetting} from '../../template/small-chart-setting';
import * as Highcharts from 'highcharts';
import {AxisTypeValue, Chart} from 'highcharts';

@Component({
  selector: 'app-small-chart-div',
  templateUrl: './small-chart-div.component.html',
  styleUrls: ['./small-chart-div.component.scss']
})
export class SmallChartDivComponent extends SmallChartSetting implements OnInit {
    updateFlag = false;

    chartInstance: Highcharts.Chart;
    @Input() series: any;
    @Input() isPositive: boolean;
    @Input() xAxisType: AxisTypeValue;
    @Input() height: number;
    @Input() width: number;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.chartConfiguration.series.push(this.series);

        // lines setting
        if (this.isPositive) {
            this.chartConfiguration.plotOptions.area = {
                lineColor: '#12ad00',
                fillColor: {
                    linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                    stops: [
                        [0, 'rgba(101,214,104,0.4)'],
                        [1, 'rgba(255, 255, 255, 0.5)']
                    ]
                },
            };
        } else {
            this.chartConfiguration.plotOptions.area = {
                lineColor: '#ad001c',
                fillColor: {
                    linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                    stops: [
                        [0, 'rgba(214,60,45,0.4)'],
                        [1, 'rgba(255, 255, 255, 0.5)']
                    ]
                },
            };
        }

        console.log('this.xAxisType :' + this.xAxisType);
        this.chartConfiguration.xAxis = {
            type: this.xAxisType,
            labels: {
                enabled: false
            },
            lineWidth: 0,
            tickWidth: 0
        };

        this.chartConfiguration.chart.height = this.height;
        // this.chartConfiguration.chart.width = this.width;

        this.updateFlag = true;
    }

    getChartInstance(chart: Chart) {
        this.chartInstance = chart;
    }

    chartRefresh(): void {
        console.log('chartRefresh');
        this.chartInstance.redraw();
        this.chartInstance.reflow();
    }
}
