import {ILabelTabInterface} from "@core/interface/i-label-tab.interface";

export class NotificationConstant {

    public static readonly ALL = 'all';
    public static readonly TRANSACTION = 'transaction';

    public static readonly NOTIFICATION_TYPE_TAB_LIST: ILabelTabInterface[] = [
        { name: 'All', value: NotificationConstant.ALL, index: 0 },
        { name: 'Transactions', value: NotificationConstant.TRANSACTION, index: 1 },
    ];
}

export enum NotificationTypeEnum {
    TRANSACTION = 'transaction'
}
