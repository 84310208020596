import {Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzIconModule} from "ng-zorro-antd/icon";
import {Select} from "@ngxs/store";
import {GlobalState} from "@core/store";
import {Observable} from "rxjs";
import {RouterLink} from "@angular/router";
import {genRouterLink} from "@util/route";
import {RouteConstant} from "@constant/route.constant";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NotificationModel} from "@model/notification/notification.model";
import {NotificationRestService} from "@api/service/api/notification.rest.service";
import {NotificationSearchModel} from "@model/notification/notification-search.model";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {
    NotificationTypeComponent
} from "@modules/pages/post-login/notification/component/notification-type/notification-type.component";
import {UiEmptyBoxComponent} from "@modules/shared-lib/common/component-revamp/ui-empty-box/ui-empty-box.component";
import {HeaderService} from '../header/header.service';

@Component({
    selector: 'app-header-notification',
    standalone: true,
    imports: [CommonModule, NzMenuModule, NzDropDownModule, NzIconModule, RouterLink, NzDividerModule, NzSpinModule, NotificationTypeComponent, NgOptimizedImage, UiEmptyBoxComponent],
    templateUrl: './header-notification.component.html',
    styleUrls: ['./header-notification.component.less']
})
export class HeaderNotificationComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    readonly genRouterLink = genRouterLink;
    readonly RouteConstant = RouteConstant;

    notificationLoading = false;
    notificationList: NotificationModel[] = [];

    constructor(
        protected headerService: HeaderService,
        private notificationRestService: NotificationRestService,
    ) {
    }

    ngOnInit(): void {
    }

    onDropdownVisibleChange(visible: boolean): void {
        if (visible) {
            this.findTodayNotification();
        }
    }

    findTodayNotification() {
        this.notificationLoading = true;
        let startOfDay = new Date();
        startOfDay.setHours(0,0,0,0);

        const searchModel: NotificationSearchModel = {
            startDate: startOfDay,
            endDate: new Date(),
            sort: ['createdDate:desc']
        }
        this.notificationRestService.findNotifications(searchModel).subscribe(res => {
            this.notificationLoading = false;
            this.notificationList = res?.data || [];
        }, err => {
            this.notificationLoading = false;
        })
    }

}
