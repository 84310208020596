import {Injectable, OnDestroy} from '@angular/core';
import {Select} from '@ngxs/store';
import {AuthState} from '@core/store';
import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RightsService implements OnDestroy {

    @Select(AuthState.getRightList) rightList: Observable<string[]>;
    private unsubscribe$ = new Subject<void>();
    rightListSubscription: Subscription;

    rightsMap = new Map();

    constructor() {
        this.rightListSubscription = this.rightList.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((res) => {
            this.rightsMap = new Map();
            this.setRightsList(res);
        });
    }

    setRightsList(data: string[]) {
        if (data) {
            for (const eachRights of data) {
                this.rightsMap.set(eachRights, eachRights);
            }
        }
        return this.rightsMap.keys();
    }

    clearMap() {
        this.rightsMap.clear();
    }

    getRightsByKey(rightsName: string) {
        return this.rightsMap.get(rightsName);
    }

    checkRights(rightsNames: string[]) {
        // this.logRights();
        if (!(rightsNames && rightsNames.length > 0)) {
            return true;
        } else {
            for (let right of rightsNames) {
                if (this.rightsMap.get(right)) {
                    return true;
                }
            }
        }
        return false;
    }

    logRights(): void {
        const log = 'Rights Acquired: ';
        let prepend = '';
        let rights = '';
        this.rightsMap.forEach(((_value, key) => {
            rights = rights + prepend + key;
            prepend = ',';
        }));
        console.log(log + rights);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.rightListSubscription.unsubscribe();
    }

}
