import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpParamsService {
    buildHttpParams(paramObject: object) {
        let params = new HttpParams();

        Object.keys(paramObject).forEach((key: string) => {
            const val = paramObject[key];
            if (val !== undefined && val !== null) {
                params = params.set(key, paramObject[key]);
            }
        });
        return params;
    }
}
