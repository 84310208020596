<nz-affix nzOffsetTop="0" class="header-affix d-print-none print:hidden">
    <app-web-scaffold>
        <div class="bg-white shadow-sm">
            <div class="container">
                <nz-row>
                    <nz-col [nzMd]="6">
                        <a (click)="redirectToDestination('/')">
                            <img [src]="logoUrl" alt="iFast Fund Management" class="header-logo"/>
                        </a>
                    </nz-col>
                    <nz-col [nzMd]="18" (outsideClick)="hideSearchBar()" [toggleOutsideClick]="!(isMobile$|async)">
                        <ng-container>
                            <ng-container [ngTemplateOutlet]="!isSearchBarShow? BeforeSearchHeader: AfterSearchHeader"></ng-container>
                        </ng-container>
                    </nz-col>
                </nz-row>
            </div>
        </div>
    </app-web-scaffold>
    <app-mobile-scaffold>
        <div class="bg-white">
            <div class="mobile-header-container py-2">
                <a [routerLink]="['/']">
                    <img [src]="logoUrl" alt="iFast Fund Management Logo" class="mobile-header-logo"/>
                </a>
                <div>
                    <button *ngIf="!isPostLoginMenu" nz-button nzType="primary" class="mr-2 ant-btn-sm"
                            (click)="showSearchDrawer()">
                        <i nz-icon nzType="search"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="showDrawer()"
                            class="ant-btn-sm">
                        <i nz-icon nzType="menu" nzTheme="outline"></i>
                    </button>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="mobileSearchDrawer"></ng-container>
        <ng-container [ngTemplateOutlet]="mobileMenuDrawer"></ng-container>
    </app-mobile-scaffold>
</nz-affix>

<ng-template #mobileMenuDrawer>
    <nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" [nzPlacement]="'right'" (nzOnClose)="closeDrawer()"
               [nzWidth]="'75%'"
               [nzFooter]="mobileMenuDrawerFooter"
               nzWrapClassName="mobile-menu-drawer"
    >
        <ng-container *nzDrawerContent>
            <ng-container *ngIf="isAuthenticated">
                <nz-avatar [nzText]="(clientName | async)[0]" class="avatar"></nz-avatar>
                <span>{{ clientName | async }}</span>
            </ng-container>
            <nav class="mobile-nav">
                <ng-container *ngIf="isAuthenticated">
                    <a (click)="redirectToDestination(RouteConstant?.PROFILE_SETTINGS)">
                        <i nz-icon nzType="user" class="mr-2"></i>Profile Settings
                    </a>
                    <a (click)="redirectToDestination(RouteConstant?.CONTACT_US)">
                        <i nz-icon nzType="contacts" class="mr-2"></i>Contact Us
                    </a>
                    <a (click)="redirectToDestination(RouteConstant?.ABOUT_US)">
                        <i nz-icon nzType="info-circle" class="mr-2"></i>About Us
                    </a>
                    <a (click)="redirectToDestination(RouteConstant?.OUR_FUNDS)">
                        <i nz-icon nzType="fund" class="mr-2"></i>Our Funds
                    </a>
                    <nz-divider></nz-divider>
                </ng-container>

                <ng-container *ngIf="!isPostLoginMenu && !isAuthenticated">
                    <a (click)="redirectToDestination(RouteConstant?.ABOUT_US)">
                        <i nz-icon nzType="info-circle" class="mr-2"></i>About Us
                    </a>
                    <nz-divider></nz-divider>
                    <a (click)="redirectToDestination(RouteConstant?.OUR_FUNDS)">
                        <i nz-icon nzType="fund" class="mr-2"></i>Our Funds
                    </a>
                    <nz-divider></nz-divider>
                    <a (click)="redirectToDestination(RouteConstant?.CONTACT_US)">
                        <i nz-icon nzType="contacts" class="mr-2"></i>Contact Us
                    </a>
                </ng-container>
                <ng-container *ngIf="isAuthenticated">
                    <ng-container *ngFor="let item of mobilePostNavData; let l=last">
                        <ng-container *ngIf="hasAccess(item.right)">
                            <ng-container *ngIf="!item.children">
                                <a (click)="redirectToDestination(item.url)">
                                    <i nz-icon [nzType]="item.icon" class="mr-2"></i>{{ item.title }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="item.children">
                                <span class="mobile-parent">
                                    <i nz-icon [nzType]="item.icon" class="mr-2"></i>{{ item.title }}
                                </span>
                                <ng-container *ngFor="let child of item.children">
                                    <ng-container *ngIf="hasAccess(child.right)">
                                        <a class="mobile-children" (click)="redirectToDestination(child.url)">
                                            <span>
                                                {{ child.title }} <span *ngIf="child.pendingNumber">({{ child.pendingNumber }})</span>
                                            </span>
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <nz-divider *ngIf="!l"></nz-divider>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </nav>
        </ng-container>
        <ng-template #mobileMenuDrawerFooter>
            <div class="text-right">
                <button *ngIf="!isAuthenticated" nz-button nzType="primary" (click)="redirectToDestination(RouteConstant?.LOGIN)">Login</button>
                <button *ngIf="isAuthenticated" nz-button nzType="primary" (click)="logout()">Logout</button>
            </div>
        </ng-template>
    </nz-drawer>
</ng-template>

<ng-template #mobileSearchDrawer>
    <nz-drawer [nzClosable]="true" [nzMaskClosable]="false" [(nzVisible)]="searchDrawerVisible" [nzPlacement]="'left'"
               nzWidth="" (nzOnClose)="closeSearchDrawer()" nzTitle="Search Products">
        <ng-container *nzDrawerContent>
            <ng-container *ngIf="searchDrawerVisible">
                     <ng-container [ngTemplateOutlet]="AfterSearchHeader"></ng-container>
            </ng-container>
        </ng-container>
    </nz-drawer>
</ng-template>

<ng-template #DrawerTitle>
    <img [src]="logoUrl" alt="homelogo" class="w-80"/>
</ng-template>


<ng-template #BeforeSearchHeader>
    <ng-container *ngIf="!isPostLoginMenu">
        <ul class="header-desktop-nav align-items-center" nz-menu nzTheme="light" nzMode="horizontal" *ngIf="!isSearchBarShow" >
            <li nz-menu-item [routerLink]="[genRouterLink(RouteConstant?.ABOUT_US)]" [nzSelected]="aboutUsNav" (click)="aboutUsNav = true;">
                <span class="font-weight-semibold">About Us</span>
            </li>
            <li nz-menu-item [routerLink]="[genRouterLink(RouteConstant?.OUR_FUNDS)]" [nzSelected]="ourFundsNav" (click)="ourFundsNav = true;">
                <span class="font-weight-semibold">Our Funds</span>
            </li>
            <li nz-menu-item [routerLink]="[genRouterLink(RouteConstant?.CONTACT_US)]" [nzSelected]="contactUsNav" (click)="contactUsNav = true;">
                <span class="font-weight-semibold">Contact Us</span>
            </li>
            <a class="search-btn" (click)="showSearchBar();">
                <i nz-icon nzType="search"></i>
            </a>
            <button *ngIf="!isAuthenticated" nz-button nzType="primary" (click)="redirectToDestination(RouteConstant?.LOGIN)">
                Login
            </button>
            <ng-container *ngIf="isAuthenticated">
                <ng-container *ngTemplateOutlet="clientProfileMenu"></ng-container>
            </ng-container>
        </ul>
    </ng-container>

   <ng-container *ngIf="isPostLoginMenu">
        <ul class="header-desktop-nav items-center" nz-menu nzTheme="light" nzMode="horizontal" *ngIf="!isSearchBarShow" >
            <ng-container *ngFor="let item of postNavData">
                <ng-container *ngIf="hasAccess(item.right)">
                    <li *ngIf="!item?.children" nz-menu-item nzMatchRouter="true" routerLinkActive="active" >
                        <a [routerLink]=[item?.url]>
                            <ng-container *ngTemplateOutlet="titleTpl"></ng-container>
                        </a>
                    </li>
                    <li *ngIf="item?.children" nz-submenu [nzTitle]="titleTpl"
                        [ngClass]="{'selected-header-route': isCurrentRoute(item?.url) }"
                        [nzPlacement]="item?.menuPlacementRight? 'bottomRight' : 'bottomLeft'"
                        nzMenuClassName="header-desktop-submenu-cdk"
                        routerLinkActive="active">
                        <ul *ngIf="item?.children">
                            <ng-container *ngFor="let child of item?.children">
                                <ng-container *ngIf="hasAccess(child.right)">
                                    <li nz-menu-item routerLinkActive="active"
                                        [class.child-active]="child.children && showChildrenRoute"
                                        [class.has-child-of-child]="child.children"
                                        (click)="checkForLogout(child?.title)"
                                    >
                                        <ng-template *ngTemplateOutlet="childTitleTpl; context: { child: child}"></ng-template>
                                    </li>
                                    <ul (mouseover)="showChildrenRoute = true"
                                        (mouseout)="showChildrenRoute = false"
                                        *ngIf="child.children " class="child-of-child-wrapper" >
                                        <ng-container *ngFor="let childOfChild of child.children">
                                            <ng-container *ngIf="hasAccess(childOfChild.right)">
                                                <li nz-menu-item routerLinkActive="active">
                                                    <ng-template *ngTemplateOutlet="childTitleTpl; context: { child: childOfChild}"></ng-template>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
                <ng-template #titleTpl>
                    <div class="flex items-center py-4">
                        <span>{{item?.title}}</span>
                        <span *ngIf="item?.children" class="material-icons ml-2">keyboard_arrow_down</span>
                    </div>

                </ng-template>
            </ng-container>
            <ng-container *ngTemplateOutlet="clientProfileMenu"></ng-container>
<!--            <a class="search-btn" (click)="showSearchBar();">-->
<!--                <i nz-icon nzType="search"></i>-->
<!--            </a>-->
<!--            <button *ngIf="isAuthenticated" nz-button nzType="primary" (click)="logout()">-->
<!--                Logout-->
<!--            </button>-->

        </ul>
   </ng-container>
</ng-template>

<ng-template #childTitleTpl let-child="child">
    <a [routerLink]="child?.url? child.url : null">
        <span> {{ child?.title }} <span *ngIf="child.pendingNumber">({{ child.pendingNumber }})</span></span>
    </a>
</ng-template>

<ng-template #clientProfileMenu>
    <ul class="header-desktop-nav items-center" nz-menu nzTheme="light" nzMode="horizontal" *ngIf="!isSearchBarShow" >
        <li nz-submenu routerLinkActive="active"
            nzPlacement="bottomRight"
            nzMenuClassName="header-desktop-submenu-cdk"
            [nzTitle]="titleTpl">
            <ul>
                <li *ngIf="!isPostLoginMenu" nz-menu-item routerLinkActive="active" >
                    <a [href]="genRouterLink(RouteConstant?.DASHBOARD)" [routerLink]="[genRouterLink(RouteConstant?.DASHBOARD)]">
                        <span>Dashboard</span>
                    </a>
                </li>
                <li nz-menu-item routerLinkActive="active" [nzMatchRouter]="true" >
                    <a [href]="genRouterLink(RouteConstant?.PROFILE_SETTINGS)" [routerLink]="[genRouterLink(RouteConstant?.PROFILE_SETTINGS)]">
                        <span>Profile Settings</span>
                    </a>
                </li>
<!--                <li nz-menu-item routerLinkActive="active" [nzMatchRouter]="true" >-->
<!--                    <span> Admin Setting</span>-->
<!--                </li>-->
                <li *ngIf="isPostLoginMenu" nz-menu-item routerLinkActive="active" [nzMatchRouter]="true" >
                    <a [href]="genRouterLink(RouteConstant?.CONTACT_US)" [routerLink]="[genRouterLink(RouteConstant?.CONTACT_US)]">
                        <span>Contact Us</span>
                    </a>
                </li>
                <li nz-menu-item routerLinkActive="active" [nzMatchRouter]="true" (click)="logout()">
                    <span> Logout</span>
                </li>
            </ul>
        </li>
    </ul>
    <ng-template #titleTpl>
        <nz-avatar [nzText]="(clientName | async)[0]" class="avatar"></nz-avatar>
        <span>{{ clientName | async }}</span>
        <span class="material-icons ml-2">keyboard_arrow_down</span>
    </ng-template>

</ng-template>

<ng-template #AfterSearchHeader>
    <div [ngClass]="{'d-flex align-items-center justify-content-end h-100 mt-2 pt-1':!(isMobile$|async),
        'd-flex justify-content-center h-100':isMobile$|async}" [formGroup]="form">
        <div class="general-search-wrapper" [ngClass]="{'max-width':!(isMobile$|async),
            'w-100':isMobile$|async}">
            <!-- <input type="text" nz-input uiAutoFocus appAutoFocus="true"> -->
            <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixTemplate" class="general-search">
                <input class="this-one" type="text" uiAutoFocus appAutoFocus="true" nz-input nzBorderless formControlName="search"
                       (keyup.arrowDown)="keyDownPress()"
                       (keyup.arrowUp)="keyUpPress()"
                       (keyup.enter)="navigateByKey()"
                />
            </nz-input-group>

            <div [ngClass]="{'search-dropdown dropdown-w': !(isMobile$|async),
                'search-dropdown-mobile w-100': isMobile$|async}" *ngIf="(searchLength$ | async) > 0" infinite-scroll
                 [scrollWindow]="false" [infiniteScrollDistance]="1"
                 (scrolled)="searchMore()">
                <div
                    [ngClass]="{'search-dropdown-item my-2':!(isMobile$|async), 'search-dropdown-item-mobile':isMobile$|async,
                    'search-dropdown-active': i ===searchBarPreHover}"
                    (click)="navigate(item)" *ngFor="let item of resultList; let i = index;">
                    {{ item?.name }}
                    <nz-divider *ngIf="isMobile$|async" style="margin: 10px 0 0 0;"></nz-divider>
                </div>
                <div
                    [ngClass]="{'search-dropdown-item my-2':!(isMobile$|async), 'search-dropdown-item-mobile':isMobile$|async}"
                    *ngIf="loading">
                    <i nz-icon nzType="loading" nzTheme="outline" class="mr-2"></i>Searching for products...
                </div>
                <div
                    [ngClass]="{'search-dropdown-item my-2':!(isMobile$|async), 'search-dropdown-item-mobile':isMobile$|async}"
                    *ngIf="!loading && resultList.length === 0">
                    <span>No matching products found</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #prefixIcon>
    <i nz-icon nzType="search"></i>
</ng-template>

<ng-template #suffixTemplate>
    <i nz-icon nz-tooltip="Close" class="ant-input-clear-icon text-md" nzTheme="fill" nzType="close-circle"
       (click)="isSearchBarShow = false"></i>
</ng-template>
