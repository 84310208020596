export class RightsConstant {

    // Rights in DB
    public static readonly FUND_PROFILE_VIEW = 'FUND_PROFILE_VIEW';
    public static readonly RECOMMENDATION_VIEW = 'RECOMMENDATION_VIEW';
    public static readonly RECOMMENDATION_SUBMIT = 'RECOMMENDATION_SUBMIT';
    public static readonly HISTORICAL_ORDER_VIEW = 'HISTORICAL_ORDER_VIEW';
    public static readonly PENDING_ORDER_VIEW = 'PENDING_ORDER_VIEW';
    public static readonly PENDING_ORDER_EDIT = 'PENDING_ORDER_EDIT'; // order amendment
    public static readonly PENDING_APPROVAL_VIEW = 'PENDING_APPROVAL_VIEW';
    public static readonly PENDING_APPROVAL_EDIT = 'PENDING_APPROVAL_EDIT'; // order approval
    public static readonly REPORT_VIEW = 'REPORT_VIEW';

    // For frontend header right
    public static readonly FUND_PROFILE: string[] = [this.FUND_PROFILE_VIEW];
    public static readonly ORDER_PAD: string[] = [this.RECOMMENDATION_VIEW];
    public static readonly HISTORICAL_ORDERS: string[] = [this.HISTORICAL_ORDER_VIEW];
    public static readonly PORTFOLIO_TRANSACTION: string[] = [...this.ORDER_PAD, ...this.HISTORICAL_ORDERS];
    public static readonly PORTFOLIO_MANAGEMENT: string[] = [...this.FUND_PROFILE, ...this.PORTFOLIO_TRANSACTION];

    public static readonly PENDING_ORDERS: string[] = [this.PENDING_ORDER_VIEW];
    public static readonly ORDER_APPROVAL: string[] = [this.PENDING_APPROVAL_VIEW];
    public static readonly APPROVALS: string[] = [...this.PENDING_ORDERS, ...this.ORDER_APPROVAL];

    // temporary value, use for profile detail display
    public static readonly REPORT: string[] = [this.REPORT_VIEW];
}
