<div class="footer-top"></div>
<div class="footer">
    <div class="mb-4 px-4" [ngClass]="(isMobile$ | async)? '' : 'container'">
        <div class="grid grid-cols-24 gap-3 xl:gap-10">
            <div class="col-span-24 lg:col-span-8">
                <img width="200" src="assets/images/ifast-footer-logo.png" alt="iFAST Fund Management" class="mh-100 mb-5"/>
                <p class="text-sm mb-0">Delivering the best investment products and solutions for investors and our partners.</p>
            </div>
            <div class="col-span-12 md:col-span-7 lg:col-span-4">
                <ng-container *ngFor="let item of footerNav | slice:0:1">
                    <ng-container *ngTemplateOutlet="footerNavItem; context: { item: item}"></ng-container>
                </ng-container>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
                <ng-container *ngFor="let item of footerNav | slice:1:2">
                    <ng-container *ngTemplateOutlet="footerNavItem; context: { item: item}"></ng-container>
                </ng-container>
            </div>
            <div class="col-span-24 md:col-span-11 lg:col-span-8">
                <p class="text-md mt-3 mb-4 font-semibold">Contact Us</p>
                <div class="flex gap-2.5 mb-4">
                    <span nz-icon nzType="mail" nzTheme="fill" class="text-primary text-[20px]"></span>
                    <a class="text-sm mb-0" href="mailto:enquiry@ifastfm.com">enquiry@ifastfm.com</a>
                </div>
                <div class="flex gap-2.5 mb-4">
                    <span nz-icon nzType="mail" nzTheme="fill" class="text-primary text-[20px]"></span>
                    <a class="text-sm mb-0" href="mailto:bd@ifastfm.com">bd@ifastfm.com</a>
                </div>
                <div class="flex gap-2.5 mb-4">
                    <span nz-icon nzType="phone" nzTheme="fill" class="text-primary text-[20px]"></span>
                    <p class="text-sm mb-0">Call (+65) 1234 5678 <br>(Weekdays: 8.30am to 5.30pm, excluding PH)</p>
                </div>
            </div>
        </div>
    </div>
    <nz-divider class="my-0"></nz-divider>
    <div class="text-sm text-center mt-5 text-black-5">©2021. All Rights Reserved. iFAST Fund Management Ltd (BVI Company Number: 1866673)</div>
</div>

<ng-template #footerNavItem let-item="item">
    <p class="text-md mt-3 mb-4 font-semibold">{{item?.title}}</p>
    <ng-container *ngFor="let item of item?.nav">
        <a *ngIf="!item?.isCustomUrl" class="text-sm mb-2" [routerLink]="item?.url">{{item?.title}}</a>
        <a *ngIf="item?.isCustomUrl" class="text-sm mb-2" [href]="item?.url" target="_blank">{{item?.title}}</a>
    </ng-container>
</ng-template>
