import {Pipe, PipeTransform} from '@angular/core';

@Pipe({standalone: true, name: 'addDotsTimePeriod'})
export class AddDotsTimePeriodPipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            const chars = value.split('');
            let result = '';
            chars.forEach(char => {
                result = result.concat(char, '.');
            });
            return result;
        }
        return value;
    }
}
