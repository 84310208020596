<div>
    <div class="mb-4">
        <div *ngIf="type">
            <i *ngIf="type === 'success'" nz-icon nzType="check-circle" nzTheme="fill" class="text-profit pop-message-icon"></i>
            <i *ngIf="type === 'error'" nz-icon nzType="close-circle" nzTheme="fill" class="text-loss pop-message-icon"></i>
        </div>
        <div *ngIf="customTypeImg" [ngClass]="customTypeImgDivCss">
            <img [ngClass]="customTypeImgCss"  [src]="customTypeImg" alt="IFAST FM"/>
        </div>
        <h5 *ngIf="titleText" class="mb-1 font-bold" [ngClass]="titleCss">{{ titleText }}</h5>
        <p *ngIf="bodyText" class="text-sm whitespace-pre-line" [ngClass]="bodyTextCss" [innerHTML]="bodyText"></p>
        <a *ngIf="linkText" [ngClass]="linkTextCss" (click)="emitData({linkText: true})">{{ linkText }}</a>
    </div>
    <div *ngIf="confirmBtn || cancelBtn" class="flex justify-center items-center gap-2">
        <button *ngIf="cancelBtn"
                [ngClass]="cancelBtnCss"
                (click)="emitData({cancel: true})"
                nz-button
                nzType="default"
                nzSize="default"
        >{{ cancelBtnHtml }}</button>
        <button *ngIf="confirmBtn"
                [ngClass]="confirmBtnCss"
                (click)="emitData({confirm: true})"
                nz-button
                nzType="primary"
                nzSize="default"
        >{{ confirmBtnHtml }}</button>
    </div>
</div>
