import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalState} from '@core/store';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ResponsiveService} from '@service/responsive.service';
import {PageService} from '@service/page.service';
import {environment} from '@env';
import {skip, tap} from 'rxjs/operators';
import {PlatformLocation} from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
    // isCollapsed = false;
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    constructor(private responsiveService: ResponsiveService,
                private pageService: PageService,
                private location: PlatformLocation,
                private cdRef: ChangeDetectorRef) {
        this.location.onPopState(() => {
            window.location.reload();
        });
    }

    ngOnInit(): void {
        console.log(`Current environment: ${environment.testMessage}`);
        this.responsiveService.observe();
        this.isMobile$.pipe(skip(1), tap(() => {
            document.querySelector('body').focus();
            this.cdRef.markForCheck();
            this.cdRef.detectChanges();
        })).subscribe();

        this.pageService.listen();
    }

    onActivate() {
        window.scrollTo({
            top: 0,
            left: 0,
        });
        // or document.body.scrollTop = 0;
        // or document.querySelector('body').scrollTo(0,0)
    }
}
