import {Component, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {GlobalState} from '@core/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-basic-container',
    templateUrl: './basic-container.component.html',
    styles: []
})
export class BasicContainerComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

    constructor() {
    }

    ngOnInit(): void {
    }

}
