import {Injectable, OnDestroy} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {SetMobileWebAction, SetPlatformAction} from '../store';
import {Platform} from '@angular/cdk/platform';
import {AppConstant} from '@constant/app.constant';
import {MobileWebBreakpoint} from '@model/i-breakpoint.model';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService implements OnDestroy {
    private subscription: Subscription;

    constructor(private bpObserver: BreakpointObserver, private store: Store, private platform: Platform) {
        const deviceObject = {
            [AppConstant.ANDROID]: this.platform.ANDROID,
            [AppConstant.IOS]: this.platform.IOS,
        };

        let currentDevice: string = AppConstant.WEB;

        Object.keys(deviceObject).forEach((eachPlatform: string) => {
            if (deviceObject[eachPlatform]) {
                currentDevice = eachPlatform;
            }
        });

        const browserObject = {
            'Internet Explorer': this.platform.TRIDENT,
            'Firefox': this.platform.FIREFOX,
            'Safari': this.platform.SAFARI,
            'Chrome': this.platform.BLINK,
            'Microsoft Edge': this.platform.EDGE,
            'Webkit': this.platform.WEBKIT
        };

        let currentBrowser = '';

        Object.keys(browserObject).forEach((eachPlatform: string) => {
            if (browserObject[eachPlatform]) {
                currentBrowser = eachPlatform;
            }
        });

        let iPadUser = false;

        if (this.platform.IOS) {
            iPadUser = this.bpObserver.isMatched('(min-width: 768px) and (max-width: 919.99px)') || false;
        }

        this.store.dispatch(new SetPlatformAction(currentDevice, currentBrowser, iPadUser));
    }

    observe() {
        this.subscription = this.bpObserver
            .observe(MobileWebBreakpoint)
            .pipe(
                map(({breakpoints}) => breakpoints),
                map(breakpointMap => {
                    return breakpointMap[MobileWebBreakpoint];
                }),
                distinctUntilChanged(),
                tap((mobileWeb: boolean) => {
                    this.store.dispatch(new SetMobileWebAction(mobileWeb));
                    window.scrollTo({
                        top: 0,
                        left: 0,
                    });
                })
            )
            .subscribe();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
