import * as Highcharts from 'highcharts';
import {HighchartsConstant} from '@constant/highchart.constant';

export class SmallChartSetting {
    public readonly Highcharts = Highcharts;
    public readonly plotColors = HighchartsConstant.PLOT_COLORS;

    public chartConfiguration: Highcharts.Options = {
        title: {
            text: '',
            align: 'left',
            y: -55,
            x: 30,
        },
        subtitle: {
            text: '',
            align: 'left',
            x: 30,
        },
        xAxis: [],
        yAxis: {
            title: {
                text: ''
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            labels: {
                enabled: false
            }
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                }
            },
        },
        lang: {
            noData: 'No Data To Display',
        },
        noData: {},
        series: [],
        chart: {
            // self set height and width
            // height: 120,
            // width: 175,
            backgroundColor: 'transparent',
            events: {
                render() {
                    setTimeout(() => {
                        this.reflow();
                    }, 0);
                }
            },
        },
        colors: [HighchartsConstant.PRIMARY_COLOR],
        legend: {
            x: 20,
            layout: 'horizontal',
            enabled: false,
            itemStyle: {
                fontSize: '16px',
            },
        },
        credits: {
            enabled: false
        },
        exporting: {
            ...HighchartsConstant.EXPORT_CONFIG,
            enabled: true
        },
        tooltip: {
            shared: true,
            valueDecimals: 6,
            formatter() {
                return this.points.reduce((_s, point) => {
                    return '<br/> ' + '<span class="text-xs" style="color:' + point.color + '">\u25CF</span> ' + Highcharts.dateFormat('%b-%Y', this.x) + ': <b>' +
                        point.y + '</b>';
                }, '<b>' + Highcharts.dateFormat('%b\' %d', this.x) + '</b>');
            },
        },
    };
}
