import {CustomiseTableColumn} from "@model/i-table.model";
import {GeneralService} from "@modules/shared-lib/common/service/general.service";
import {IKeyValueMapModel} from "@model/i-key-value-map.model";

export function sort(sortAttribute: { key: string; value: string }, inputData: any[]) {
    const dataArr = deepCopy(inputData);

    if (sortAttribute.key === '' || sortAttribute.value === null) {
        return dataArr;
    }

    return dataArr.sort((a: { [x: string]: string; }, b: { [x: string]: string; }) => {
        const isAsc = sortAttribute.value === 'ascend';
        switch (sortAttribute.key) {
            case sortAttribute.key:
                return compare(
                    typeof a[sortAttribute.key] !== 'string'
                        ? a[sortAttribute.key]
                        : a[sortAttribute.key].toUpperCase(),
                    typeof b[sortAttribute.key] !== 'string'
                        ? b[sortAttribute.key]
                        : b[sortAttribute.key].toUpperCase(),
                    isAsc
                );
            default:
                return 0;
        }
    });
}

export function deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

export function compare(a: string | number, b: string | number, isAsc: boolean) {
    // null value is - (dash)
    if (!a && a !== 0) { a = '-'; }
    if (!b && b !== 0) { b = '-'; }

    // whole number is date
    // if (typeof a == 'number' && a % 1 == 0) a = formatDate(a 'yyyy-MM-dd HH:mm:ss', 'en');
    // if (typeof b == 'number' && b % 1 == 0) b = formatDate(b, 'yyyy-MM-dd HH:mm:ss', 'en');

    if (a === b) { return 0; }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function changeProductTableStyle(headerList: CustomiseTableColumn[], originalHeaderList: CustomiseTableColumn[], isMobile: boolean) {
    if (headerList) {
        headerList.forEach(header => {
            if (header) {
                if ('productName' === header.key || 'fundName' === header.key) {
                    if (isMobile) {
                        header.nzLeft = true;
                        header.nzWidth = '100px';
                    }
                    else {
                        header.nzWidth = '250px';
                    }
                }
                else {
                    if (isMobile) {
                        header.nzLeft = false;
                    }
                    else {
                        const oriheader = originalHeaderList.find(originalHeader => originalHeader.key === header.key);
                        if (oriheader) {
                            header.nzLeft = oriheader.nzLeft || false;
                        }
                    }
                }

                if (header.showSort) {
                    header.sortFn = (a, b) => GeneralService.sortFn(a, b, header.key);
                }
            }
        });
    }
}

export function changeTableColumnSeq(headerList: CustomiseTableColumn[], originalHeaderList: CustomiseTableColumn[], isMobile: boolean) {
    if (headerList && headerList.length) {
        headerList.sort((a, b) => {
            let seqA: number, seqB: number;

            if (isMobile) {
                // Use mobileViewSeq for mobile view
                seqA = a.mobileViewSeq ?? Number.MAX_SAFE_INTEGER;
                seqB = b.mobileViewSeq ?? Number.MAX_SAFE_INTEGER;
            } else {
                // Use seq for desktop view, fallback to original list order if seq is not defined
                seqA = a.seq ?? findOriginalSeq(a, originalHeaderList);
                seqB = b.seq ?? findOriginalSeq(b, originalHeaderList);
            }

            return seqA - seqB;
        });
    }
}

function findOriginalSeq(column: CustomiseTableColumn, originalList: CustomiseTableColumn[]): number {
    const index = originalList.findIndex(origColumn => origColumn.key === column.key);
    return index !== -1 ? index : Number.MAX_SAFE_INTEGER;
}

export function changeActionTableColumnSeq(headerList: CustomiseTableColumn[], originalHeaderList: CustomiseTableColumn[], isMobile: boolean) {
    if (headerList) {
        const actionHeader = headerList.find(header => header.key === 'action');
        if (actionHeader) {
            headerList.splice(headerList.indexOf(actionHeader), 1);
            if (isMobile) {
                headerList.unshift(actionHeader);
            }
            else {
                headerList.push(actionHeader);
            }
        }
    }
}

export function setTableSortingFn(headerList: CustomiseTableColumn[]) {
    headerList.forEach(header => {
        if (header.showSort) {
            header.sortFn = (a, b) => GeneralService.sortFn(a, b, header.key);
        }
    });
}

export function changeSetToOptions(valueSet: Set<string>): IKeyValueMapModel[] {
    return Array.from(valueSet).map(value => ({ label: value, value }));
}

export function changeTableColumnWidth(headerList: CustomiseTableColumn[], key: string, width: number) {
    const header = headerList.find(header => header.key === key);
    if (header) {
        header.nzWidth = width + 'px';
    }
}
