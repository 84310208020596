import {Injectable, Renderer2, RendererFactory2} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    private renderer: Renderer2;
    private overlay: HTMLDivElement | null = null;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    displayOverlay() {
        const overlayElement = document.getElementById('overlay');
        if (!overlayElement) {
            this.overlay = this.renderer.createElement('div');
            this.renderer.setAttribute(this.overlay,'id', 'overlay')
            this.renderer.setStyle(this.overlay, 'position', 'fixed');
            this.renderer.setStyle(this.overlay, 'top', '0');
            this.renderer.setStyle(this.overlay, 'left', '0');
            this.renderer.setStyle(this.overlay, 'width', '100%');
            this.renderer.setStyle(this.overlay, 'height', '100%');
            this.renderer.setStyle(this.overlay, 'backgroundColor', 'white'); // Semi-transparent black background
            this.renderer.setStyle(this.overlay, 'zIndex', '9999'); // Ensure the overlay is on top
            this.renderer.appendChild(document.body, this.overlay);
        }
    }

    removeOverlay() {
        setTimeout(() => {
            const overlayElement = document.getElementById('overlay');
            console.log('overlayElement', overlayElement);
            if (overlayElement) {
                this.renderer.removeChild(document.body, overlayElement);
                this.overlay = null;
                console.log('done remove');
            }
        }, 700);
    }
}
